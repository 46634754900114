// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import wazoToGepani from "../api-configuration/wazoToGenapi.json";
import firebaseConfigurations from "../api-configuration/firebase-config.json";

export const getDb = (wazoTenantId) => {
  // la db a besoin d'être initialisée une premiere fois avant la connexion de l'utilisateur
  if ('' === wazoTenantId || undefined === wazoTenantId) {
    const firebaseConfig = firebaseConfigurations['default'];
    const app = initializeApp(firebaseConfig);
  
    return getFirestore(app)
  }

  const firebaseConfigurationName = wazoToGepani[wazoTenantId]["firebase-config"]
  const firebaseConfig = firebaseConfigurations[firebaseConfigurationName];

  const app = initializeApp(firebaseConfig, wazoTenantId);

  return getFirestore(app)
}

export const getGenapiLoginUrl = (wazoTenantId) => {
  if ('' === wazoTenantId || undefined === wazoTenantId) {
    return null;
  }

  return wazoToGepani[wazoTenantId]['genapiLoginApi']
}

export const getGenapiApiUrl = (wazoTenantId) => {
  if ('' === wazoTenantId || undefined === wazoTenantId) {
    return null;
  }

  return wazoToGepani[wazoTenantId]['genapiApi']
}

export const getGenapiSecret = (wazoTenantId) => {
  if ('' === wazoTenantId || undefined === wazoTenantId) {
    return null;
  }

  return wazoToGepani[wazoTenantId]['genapiSecret']
}

export const getGenapiTenantId = (wazoTenantId) => {
  if ('' === wazoTenantId || undefined === wazoTenantId) {
    return null;
  }

  return wazoToGepani[wazoTenantId]['genapiTenantId']
}
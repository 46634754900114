import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation, useParams } from "react-router-dom";
// material
import { 
  TextField,
  Autocomplete,
  Paper,
} from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
//consts
import { PAGE_CALL_HISTORY, PAGE_CLIENT } from '../pages';

const HistoryCallModalSelectClient = ({
  clients,
  client,
  call,
  postCall,
  updateCallByCollaboratorUuid,
  updateCallByClientId,
  setIsEditingClient,
  saveIsDisplayedHistoryCallModal,
  isEditingClient,
  resetFolderId,
  saveSnackbar,
  saveTabIndex,
}) => {
  const navigate = useNavigate(null);
  const location = useLocation();
  const routeParams = useParams();

  // rajout le numéro de telephone de l'appelant dans la liste
  // si selectionner cela enlevera le client du call (car call.clientId sera égale à 0)
  const defaultClient = { id: 0, denomination: call.callNumber }
  const currentClients = [...clients, defaultClient]
  const currentClient = client ?? defaultClient
  
  const options = currentClients.map((option) => {
    const firstLetter = option.denomination[0].toUpperCase();
    
    return {
      firstLetter: 0 === option.id
        ? ' Retirer' // espace au début rajouté volontairement afin que retirer soit en 1er dans la liste
        : firstLetter,
        ...option,
    };
  });


  // le collaboteur selectionné sera aussi en couleur
  const CustomPaper = ({ children }) => {
    return (
      <Paper 
        sx={{
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option[aria-selected='true']": {
              bgcolor: "secondary.main",
              "&.Mui-focused": {
                bgcolor: "secondary.dark",
              }
            }
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: "secondary.light",
          },
          width: `300px`,
          textTransform: 'capitalize'
        }}
      >
        {children}
      </Paper>
    );
  }

  const onChangeClientDenomination = (newClient) => {
    if (newClient && (client?.id ?? 0) !== newClient?.id) {
      // met à jour le call en bdd et le state calls.calls
      postCall({ ...call, clientId: newClient.id })
      setIsEditingClient(false)
      // met à jour calls.callsByCollaboratorUuid
      if (PAGE_CALL_HISTORY === location.pathname) {
        updateCallByCollaboratorUuid({ ...call, clientId: newClient.id })
      }
      // met à jour calls.callsByClientId
      if (PAGE_CLIENT.replace(':clientId', routeParams.clientId) === location.pathname) {
        updateCallByClientId({ ...call, clientId: newClient.id })
        // force fermeture de la modal si le client est retiré
        if (0 === newClient.id) {
          saveIsDisplayedHistoryCallModal(call.id, false)
        // sinon switch de page sur le nouveau client
        } else {
          saveTabIndex(PAGE_CLIENT.replace(':clientId', newClient.id), 1)
          navigate(`/clients/${newClient.id}`)
        }
      }
      if (0 === newClient.id) {
        saveSnackbar('Client retiré avec succès', 'success')
      } else {
        saveSnackbar('Client mis à jour', 'success')
      }
      resetFolderId()
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setIsEditingClient(false)}>
      <Autocomplete
        sx={{ width: ['250px', `${currentClient.denomination.length * 8 + 75}px`], p: 0, mb: 2 }}
        value={currentClient}
        onChange={(e, client) => onChangeClientDenomination(client)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.denomination}
        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        open={isEditingClient}
        autoSelect
        PaperComponent={CustomPaper}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.denomination}
            </li>
          );
        }}
        renderInput={(params, option) => (
          <TextField
            {...params}
            variant='standard'
            autoFocus
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </ClickAwayListener>
  );
}

HistoryCallModalSelectClient.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.exact({
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      id: PropTypes.number.isRequired,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      tenantId: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  client: PropTypes.exact({
    adresse: PropTypes.exact({
      commune: PropTypes.exact({
        codeInsee: PropTypes.string,
        codePostal: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
      }),
      complementAdresse: PropTypes.string.isRequired,
      extensionNumero: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string,
      }),
      numero: PropTypes.string.isRequired,
      pays: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeVoie: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      voie: PropTypes.string.isRequired,
    }),
    civilite: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    dateCreation: PropTypes.string.isRequired,
    dateDeces: PropTypes.string,
    dateDernierAcces: PropTypes.string,
    dateModification: PropTypes.string.isRequired,
    dateNaissance: PropTypes.exact({
      jour: PropTypes.number,
      mois: PropTypes.number,
      annee: PropTypes.number.isRequired,
    }),
    denomination: PropTypes.string.isRequired,
    email: PropTypes.string,
    etatMarital: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    formeSociete: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    numero: PropTypes.string.isRequired,
    registreCommerce: PropTypes.string,
    siren: PropTypes.string,
    telephones: PropTypes.arrayOf(
      PropTypes.exact({
        typeTelephone: PropTypes.string.isRequired,
        numero: PropTypes.string.isRequired,
      }),
    ),
    typeClient: PropTypes.string.isRequired,
    dateRecuperation: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }),
  }).isRequired,
  // met à jour un appel en bdd et dans le state calls.calls
  postCall: PropTypes.func.isRequired,
  // met à jour le state le state calls.callsByCollaboratorUuid
  updateCallByCollaboratorUuid: PropTypes.func.isRequired,
  // met à jour le state le state calls.callsByClientId
  updateCallByClientId: PropTypes.func.isRequired,
  // pour activer ou desactiver l'input
  isEditingClient: PropTypes.bool.isRequired,
  setIsEditingClient: PropTypes.func.isRequired,
  // permet d'afficher ou de cacher la historyCallModal
  saveIsDisplayedHistoryCallModal: PropTypes.func.isRequired,
  // permet de repasser historyCall.folderId à sa valeur par defaut (0)
  resetFolderId: PropTypes.func.isRequired,
  // affiche une snackbar pour lamaj du client
  saveSnackbar: PropTypes.func.isRequired,
}

export default HistoryCallModalSelectClient
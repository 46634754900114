import Socket, { LOGOUT_CODE } from "../../Socket";
import { timer, getTwoDigitTime } from "../../utils";
// actions
import {
  DISCONNECT_WEBSOCKET_WAZO,
  CONNECT_WEBSOCKET_WAZO,
  saveIsConnectingWebsocket,
} from "../websocketWazo/actionWebsocketWazo";
// Firebase
import { Timestamp } from "firebase/firestore";
import {
  saveCall,
  updateCall,
  postCall,
  postHistoryCall,
  deleteFilter,
  updateCallId,
} from "../calls/actionCalls";

import { saveTabIndex } from "../tabs/actionTabs";
// consts
import { PAGE_CALLS } from '../../pages';

let socket;
const createWebsocket = (server, token) => {
  socket = new Socket();
  socket.init(
    `wss://${server}/api/websocketd/?token=${token}&version=2`,
    [
      'call_created',
      'call_ended',
      'call_answered',
      'call_held',
      'call_resumed',
    ],
  )
}

const middlewareWebsocketWazo = (store) => (next) => (action) => {
  const handleCallEnded = (call) => {
    call.status = 'call_ended';
    // créer l'appel en bdd
    store.dispatch(postCall(call))
    store.dispatch(saveTabIndex(PAGE_CALLS, 3));
    // récupere l'historique de l'appel
    const historyCall = store.getState().calls.historyCalls.filter(
      (historyCall) => historyCall.id === call.historyId,
    )?.[0]
    // si l'utilisateur a saisi un historique, le pousse en bdd
    if (historyCall) {
      store.dispatch(postHistoryCall(historyCall))
    }

    if (store.getState().calls.filters?.[PAGE_CALLS]) {
      store.dispatch(deleteFilter(PAGE_CALLS));
    }
  }

  const setCallStatus = (call) => {
    let status = 'call_ended';

    if ('Ring' === call?.status || 'Down' === call?.status || 'Ringing' === call?.status) {
      status = 'call_created';
    }

    if ('Up' === call?.status || 'outbound' === call?.direction) {
      status = 'call_answered';
    }

    if (call?.on_hold) {
      status = 'call_held';
    }

    return { ...call, status }
  }

  const setCallClientId = (call) => {
    // filtrer clients en fonction du caller_id_number présent dans data
    const currentClients = store.getState().clients.clients;

    const foundClients = currentClients.filter(client => {
      let result = false;

      const formatCallNumber = (callNumber) => (
        callNumber
          // Remove '.' from the number
          .split('.').join("")
          // Replace '+33' by '0' 
          .replace('+33', '0')
          // Replace '0033' by '0' 
          .replace('0033', '0')
          // Remove spaces from number 
          .replace(/\s+/g, '')
      )

      // si un des numéros de client.telephones correspond au numéro de wazo alors le filter recupère le client
      client.telephones.forEach((telephone) => {
        const foundedClient = formatCallNumber(telephone.numero) === call.peer_caller_id_number

        if (foundedClient) {
          result = true;
        }
      });

      return result;
    });

    const clientId = foundClients[0]?.id ?? 0;

    return { ...call, clientId }
  }

  const setCallStartTime = (call) => {
    const now = new Date();

    const hours = getTwoDigitTime(now.getHours());
    const minutes = getTwoDigitTime(now.getMinutes());
    const seconds = getTwoDigitTime(now.getSeconds());

    const startTime = `${hours}:${minutes}:${seconds}`;

    return { ...call, startTime };
  }

  const setCallHeldStartTime = (call) => {
    const now = new Date();

    const hours = getTwoDigitTime(now.getHours());
    const minutes = getTwoDigitTime(now.getMinutes());
    const seconds = getTwoDigitTime(now.getSeconds());

    const heldStartTime = `${hours}:${minutes}:${seconds}`;

    return { ...call, heldStartTime };
  }

  const setCallFormat = (call) => {
    const { peer_caller_id_number, call_id, direction, creation_time, caller_id_name, caller_id_number, user_uuid, conversation_id, startTime, clientId, status } = call;

    return {
      id: call_id,
      callNumber: peer_caller_id_number,
      username: caller_id_name,
      userExtension: caller_id_number,
      direction,
      startTime,
      heldStartTime: '0',
      date: Timestamp.fromDate(new Date(creation_time)),
      status,
      userId: user_uuid,
      historyId: conversation_id,
      duration: 0,
      clientId,
    }
  }

  const updateTabIndex = (call) => {
    if (call) {
      if (call.on_hold) {
        store.dispatch(saveTabIndex(PAGE_CALLS, 1));
      } else if (call.hangup_time) {
        store.dispatch(saveTabIndex(PAGE_CALLS, 3));
      } else if ('Ring' === call.status || 'Down' === call.status || 'Ringing' === call.status) {
        if ('outbound' === call.direction) {
          store.dispatch(saveTabIndex(PAGE_CALLS, 0))
        } else {
          store.dispatch(saveTabIndex(PAGE_CALLS, 2))
        }
      } else if ('Up' === call.status) {
        store.dispatch(saveTabIndex(PAGE_CALLS, 0));
      }
    }
  }

  // Traitement doublon wazo
  const getExistingCall = (newCall, calls) => calls.find(
  (call) => {
      if(!Boolean(newCall)) return

      return (
        call.historyId === newCall.historyId  &&
        call.callNumber === newCall.callNumber &&
        call.username === newCall.username &&
        call.userExtension === newCall.userExtension &&
        Math.abs(call.date.seconds - newCall.date.seconds) <= 1 
      )
    }
  )

  switch (action.type) {
    case CONNECT_WEBSOCKET_WAZO:
      console.log('CONNECT_WEBSOCKET_WAZO');
      const updateCallStatus = async () => {
        try {
          const rawWazoOnProgressCalls = await fetch(`https://${store.getState().login.server}/api/calld/1.0/users/me/calls`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": store.getState().login.token
            },
          })
          const wazoOnProgressCallsItems = await rawWazoOnProgressCalls.json();
          const wazoOnProgressCalls = wazoOnProgressCallsItems.items;

          // switch sur la tab du dernier appel reçu
          const lastWazoOnProgressCall = wazoOnProgressCalls[wazoOnProgressCalls.length - 1];

          if (lastWazoOnProgressCall) {
            console.log("updateTabIndex", {lastWazoOnProgressCall});
            updateTabIndex(lastWazoOnProgressCall);
          }

          // transforme wazoOnProgressCalls en objet avec l'id du call en clé
          let formatedWazoOnProgressCalls = {};

          wazoOnProgressCalls.forEach((wazoOnProgressCall) => {
            formatedWazoOnProgressCalls = {
              ...formatedWazoOnProgressCalls,
              [wazoOnProgressCall.call_id]: { ...wazoOnProgressCall },
            }
          })

          const currentCalls = store.getState().calls.calls.filter((call) => formatedWazoOnProgressCalls?.[call.id]);
          
          const inProgressCalls = store.getState().calls.calls.filter(call => call.status !== "call_ended")
          
          console.log({
            currentCalls,
            inProgressCalls,
            currentLength: currentCalls.length,
            calls: store.getState().calls.calls,
            callsLength: store.getState().calls.calls.length,
            wazoOnProgressCalls,
            wazoOnProgressCallsLenght: wazoOnProgressCalls.length
          })

          if (0 !== currentCalls.length) {
            // compare la liste des appels en cours côté wazo et la liste des calls en state pour mettre à jour call.status
            currentCalls.forEach((currentCall) => {
              if ("call_ended" !== currentCall.status) {
                let wazoOnProgressCall = formatedWazoOnProgressCalls?.[currentCall.id]
                wazoOnProgressCall = setCallStatus(wazoOnProgressCall);
                store.dispatch(updateCall({ ...currentCall, status: wazoOnProgressCall.status }))
              }
            })
          }
          // si il existe un appel en cours dans le state mais qui est terminé dans wazo. (suite a un transfert en dehors de l'onglet cti dans wazo desktop)
          else if (0 === currentCalls.length && 0 !== store.getState().calls.calls.length && 0 === wazoOnProgressCalls.length) {
            const inProgressCalls = store.getState().calls.calls.filter((call) => "call_ended" !== call.status);
            
            console.log({ inProgressCalls })
            inProgressCalls.forEach(
              (call) => {
                const [hours, minutes, seconds] = call.startTime.split(":");
                const date = new Date();
                date.setHours(hours);
                date.setMinutes(minutes);
                date.setSeconds(seconds);
                const endTime = new Date();

                handleCallEnded({ ...call, date: Timestamp.fromDate(new Date(call.date.seconds * 1000)), duration: Math.round((endTime - date) / 1000) })
              }
            )
          }
          // si il existe un appel en cours mais qu'il n'est pas trouvé dans le store.
          else {
            wazoOnProgressCalls.forEach((wazoOnProgressCall) => {
              let newCall = setCallStatus(wazoOnProgressCall)
              newCall = setCallClientId(newCall);
              newCall = setCallStartTime(newCall);
              newCall = setCallFormat(newCall);

              const existingCall = getExistingCall(newCall, store.getState().calls.calls)
              console.log({newCall, existingCall});
              
              if (existingCall) {
                console.log("doublon : si il existe un appel en cours mais qu'il n'est pas trouvé dans le store.")
                store.dispatch(updateCallId(existingCall.id, newCall.id));
                store.dispatch(updateCall(newCall));
              } else {
                store.dispatch(saveCall(newCall))
              }

            })
          }
        } catch (error) {
          console.log("updateCallStatus ~ error:", error)
        } finally {
          store.dispatch(saveIsConnectingWebsocket(false));
        }
      }

      const connectWebsocket = (token) => {
        createWebsocket(store.getState().login.server, token);

        const onCallCreated = (data) => {
          console.log('call_created', data)
          let call = {};

          call = setCallClientId(data);
          call = setCallStartTime(call);
          call = setCallStatus(call);
          call = setCallFormat(call);

          const existingCall = getExistingCall(call, store.getState().calls.calls)

          if (existingCall) {
            console.log('doublon')
          }
          else {
            store.dispatch(saveCall(call));
          }
        }

        const onCallAnswered = (data) => {
          console.log('call_answered', data)

          const { call_id, answer_time, conversation_id, peer_caller_id_number, caller_id_name, caller_id_number, creation_time } = data;

          let updatedCall = {
            id: call_id,
            status: 'call_answered',
            date: Timestamp.fromDate(new Date(answer_time)),
          }

          const existingCall = getExistingCall({
            historyId: conversation_id,
            callNumber: peer_caller_id_number,
            username: caller_id_name,
            userExtension: caller_id_number,
            date: { seconds: parseInt((new Date(creation_time)).getTime().toString().slice(0,-3)) }
          }, store.getState().calls.calls)
          
          if (existingCall) {
            store.dispatch(updateCallId(existingCall.id, updatedCall.id));
          }

          updatedCall = setCallStartTime(updatedCall);

          store.dispatch(updateCall(updatedCall));
        }

        const onCallHeld = (data) => {
          // console.log('call_held', data);
          let heldCall = {
            id: data.data.call_id,
            status: 'call_held',
          }

          heldCall = setCallHeldStartTime(heldCall);

          store.dispatch(updateCall(heldCall));
        }

        const onCallResumed = (data) => {
          // console.log('call_resumed', data);
          store.dispatch(updateCall(
            {
              id: data.call_id,
              status: 'call_answered',
            }
          ))
        }

        const onCallEnded = (data) => {
          // console.log('call_ended', data);

          const call = store.getState().calls.calls.find(
            (call) => call.id === data.call_id
          )

          // Gestion erreur si rafracihissement pendant un appel
          if (call) {
            // data.answer_time n'existe pas si l'appel n'a pas été decroché
            const startTime = new Date(data.answer_time ?? data.creation_time);
            const endTime = data.hangup_time ? new Date(data.hangup_time) : new Date();
            // si l'appel n'est pas decroché la durée sera de 0
            console.log({
              'debugOnCallEnded': {
                dataAnswerTime: data.answer_time,
                dataCreationTime: data.creation_time,
                dataHangupTime: data.hangup_time,
                startTime: new Date(data.answer_time ?? data.creation_time),
                endTime: data.hangup_time ? new Date(data.hangup_time) : new Date(),
                duration: data.answer_time ? Math.round((endTime - startTime) / 1000) : 0,
              }
            })
            call.duration = data.answer_time ? Math.round((endTime - startTime) / 1000) : 0;
            call.date = Timestamp.fromDate(startTime);

            handleCallEnded(call);
          }
        }

        socket.on('message', (event) => {
          const msg = JSON.parse(event.data);
          // eslint-disable-next-line default-case
          switch (msg.op) {
            case 'init':
              socket.connect()
              break

            case 'event':
              const { data } = msg.data;
              if (store.getState().login.uuid === data.user_uuid) {
                // eslint-disable-next-line default-case
                switch (msg.data.name) {
                  case 'call_created':
                    onCallCreated(data);
                    // switch sur la tab de l'appel reçu
                    updateTabIndex(data);
                    console.log("updateTabIndex call_created", {data});
                    break;

                  case 'call_answered':
                    onCallAnswered(data);
                    // switch sur la tab de l'appel reçu
                    updateTabIndex(data);
                    console.log("updateTabIndex call_answered", {data});
                    break;

                  case 'call_held':
                    onCallHeld(msg.data);
                    // switch sur la tab de l'appel reçu
                    updateTabIndex({ on_hold: true });
                    break;

                  case 'call_resumed':
                    onCallResumed(data);
                    // switch sur la tab de l'appel reçu
                    updateTabIndex({ status: 'Up' });
                    break;

                  case 'call_ended':
                    onCallEnded(data)
                    // switch sur la tab de l'appel reçu
                    updateTabIndex(data);
                    break;
                }
              }
              break;
          }
        })

        socket.on('error', (err) => {
          // console.log("err:", err)
          clearTimeout(socket.pingTimeout)
          // deconnecte le websocket
          socket.disconnect(err.code, 'error');
        })

        socket.on('close', (event) => {
          // console.log("close", event)
          clearTimeout(socket.pingTimeout)
          // reconnect le websocket
          if (LOGOUT_CODE !== event.code) {
            const reconnect = async () => {
              await timer(5000);
              socket = connectWebsocket(token);
            }
            reconnect();
          }

        })

        return socket;
      };

      const init = () => {
        try {
          // se reconnecte au websocket
          connectWebsocket(store.getState().login.token)
          // synchronise les states à la liste des appel present dans wazo
          updateCallStatus();
        } catch (error) {
          console.log("init ~ error:", error)
        }
      }
      init();

      next(action);
      break;

    case DISCONNECT_WEBSOCKET_WAZO:
      if (socket) socket.disconnect();

      next(action);
      break

    default: next(action)
  }
}

export default middlewareWebsocketWazo;

/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

const RequireAuth = ({
  children,
  isConnected,
  isConnectingWithContext,
  token,
  isCheckingWazoToken,
  isCheckingIsMostRecentVersion,
  isConnectingToWazoApp,
  isDisconnectWazoApp,
  currentTabIndex,
  checkIsValidWazoToken,
  connectToWazoApp,
  checkIsMostRecentVersion,
  signInWazoWithContext,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // permet de s'abonner au evenement de WazoDesktop 
  useEffect(() => {
    // ne doit pas se lancer si la deconnexion a été lancé depuis wazo desktio (correpond a l'event dans CONNECT_TO_WAZO_APP.app.onLogout())
    if (!isDisconnectWazoApp)
    connectToWazoApp()
  }, [connectToWazoApp, isDisconnectWazoApp])

  // verifie si la derniere version eté et le cache est vidé pour forcer la maj
  useEffect(() => {
    // isDisconnectWazoApp est rajouté pour eviter que l'application se reconnecte apres une deconnexion effectuée depuis wazoDesktop
    // il faudra que la connexion a wazo app soit faite pour lancer être redirigé vers /login
    if (!isConnectingToWazoApp && !isDisconnectWazoApp) {
      checkIsMostRecentVersion();
    }
  // sinon l'utilisateur est deconnec

  }, [checkIsMostRecentVersion, isConnectingToWazoApp])
  


  // verifie si le token wazo est toujours valide
  useEffect(() => {
    // isDisconnectWazoApp est rajouté pour eviter que l'application se reconnecte apres une deconnexion effectuée depuis wazoDesktop
    // il faudra que la connexion a wazo app soit faite pour lancer être redirigé vers /login
    if (!isConnectingToWazoApp && !isDisconnectWazoApp) {
      checkIsValidWazoToken(token);
    }
    // on veut verifier la validité du token a chaque fois que l'onglet change (location.pathname)
  }, [checkIsValidWazoToken, isConnectingToWazoApp, location.pathname])

  // si le token n'est pas valide, tente de le recupérer via le contexte
  useEffect(() => {
    // isDisconnectWazoApp est rajouté pour eviter que l'application se reconnecte apres une deconnexion effectuée depuis wazoDesktop
    // il faudra que le check du token wazo, la verification de la version et la connexion a wazo app soit fait pour lancer signInWazoWithContext
    if (
      !isCheckingIsMostRecentVersion
      && !isCheckingWazoToken
      && !isConnected
      && !isConnectingToWazoApp
      && !isDisconnectWazoApp
    ) {
      signInWazoWithContext()
    }
    if (isConnected && currentTabIndex?.[0]) {
      navigate(Object.keys(currentTabIndex)?.[0]);
    }
  }, [signInWazoWithContext, isCheckingWazoToken, isCheckingIsMostRecentVersion, isConnected, isConnectingToWazoApp])
  
  // sinon le redirige vers la page de login
  useEffect(() => {
    // isDisconnectWazoApp est rajouté pour eviter que l'application se reconnecte apres une deconnexion effectuée depuis wazoDesktop
    // il faudra que le check du token wazo, la verification de la version, la connexion a wazo app et que la connexion via le context echou pour lancer être redirigé vers /login
    if (
      !isConnected
      && !isConnectingWithContext
      && !isCheckingWazoToken
      && !isCheckingIsMostRecentVersion
      && !isConnectingToWazoApp
      && !isDisconnectWazoApp
    ) {
      navigate('/login');
    }
  }, [isConnected, isCheckingWazoToken, isConnectingWithContext, isCheckingIsMostRecentVersion, isConnectingToWazoApp, navigate])

  return children;
};

RequireAuth.propTypes = {
  // Element pour lequel il faudra être identifié pour y accéder
  children: PropTypes.object.isRequired,
  // si l'utilisateur est connecté
  isConnected: PropTypes.bool.isRequired,
  // est en train de se connecter via le context de Wazo desktop
  isConnectingWithContext: PropTypes.bool.isRequired,
  // token de connexion wazo
  token: PropTypes.string,
  // est en train de verifier le tolen wazo
  isCheckingWazoToken: PropTypes.bool.isRequired,
  // est en train de vérifier la version de l'application
  isCheckingIsMostRecentVersion: PropTypes.bool.isRequired,
  // est en train de se connecter aux evenements de wazo desktop
  isConnectingToWazoApp: PropTypes.bool.isRequired,
  // est deconnecté depuis wazo desktop
  isDisconnectWazoApp: PropTypes.bool.isRequired,
  // indique sur quel l'onglet et la page en cours d'utilisation
  currentTabIndex: PropTypes.object.isRequired,
  // verifie si le token wazo est toujours valide
  checkIsValidWazoToken: PropTypes.func.isRequired,
  // permet de s'abonner aux evenement transmis par wazo desktop
  connectToWazoApp: PropTypes.func.isRequired,
    // permet de verifier que l'application est bien à jour.
  checkIsMostRecentVersion: PropTypes.func.isRequired,
  // récupére les informations de connextion depuis wazo desktop
  signInWazoWithContext: PropTypes.func.isRequired,
}

export default RequireAuth;
import { legacy_createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

// reducers
import reducerClients from './clients/reducerClients';
import reducerLogin from './login/reducerLogin';
import reducerCalls from './calls/reducerCalls';
import reducerTabs from './tabs/reducerTabs';
import reducerUpdateApp from './updateApp/reducerUpdateApp';
import reducerUsers from './users/reducerUsers';
import reducerSnackbar from './snackbar/reducerSnackbar';
import reducerWebsocketWazo from './websocketWazo/reducerWebsocketWazo';
// middlewares
import middlewareClients from './clients/middlewareClients';
import middlewareLogin from './login/middlewareLogin';
import middlewareCalls from './calls/middlewareCalls';
import middlewareWebsocketWazo from './websocketWazo/middlewareWebsocketWazo'
import middlewareWebsocketFirebase from './websocketFirebase/middlewareWebsocketFirebase'
import middlewareUsers from './users/middlewareUsers'

const enhancers = composeWithDevTools(
  applyMiddleware(
    middlewareClients,
    middlewareLogin,
    middlewareCalls,
    middlewareWebsocketWazo,
    middlewareWebsocketFirebase,
    middlewareUsers,
  ),
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'tabs',
    'updateApp',
  ],
  blacklist: [
    'snackbar',
    'clients',
    'login',
    'calls',
    'users',
    'websocketWazo',
  ]
}

const callsPersistConfig = {
  key: 'calls',
  storage: storage,
  blacklist: [
    'isFetchingCalls',
    'isFetchingMoreCalls',
    'isFetchedCalls',
  ],
}

const usersPersistConfig = {
  key: 'users',
  storage: storage,
  blacklist: [
    'isFetchingUsers',
  ],
}

const clientsPersistConfig = {
  key: 'clients',
  storage: storage,
  blacklist: [
    'isFetchingFolders',
    'isFetchingClients',
    'isFetchingFiles',
    'isUploadingFile',
    'isFetchingFullClient',
    'isFetchingClientError',
  ],
}

const loginPersistConfig = {
  key: 'login',
  storage: storage,
  blacklist: [
    'isConnectingToWazoApp',
    'isPageLoading',
    'isCheckingWazoToken',
    'isConnectingWithContext',
    'isDisconnectWazoApp',
    'isConnected',
  ],
}

const websocketWazoConfig = {
  key: 'websocketWazo',
  storage: storage,
  blacklist: [
    'isConnectingWebsocket',
  ],
}

const rootReducer = combineReducers({
  clients: persistReducer(clientsPersistConfig, reducerClients),
  login: persistReducer(loginPersistConfig, reducerLogin),
  calls: persistReducer(callsPersistConfig, reducerCalls),
  tabs: reducerTabs,
  updateApp: reducerUpdateApp,
  users: persistReducer(usersPersistConfig, reducerUsers),
  snackbar: reducerSnackbar,
  websocketWazo: persistReducer(websocketWazoConfig, reducerWebsocketWazo),
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = legacy_createStore(persistedReducer, enhancers);
export const persistor = persistStore(store)

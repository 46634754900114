// React
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from "react-router-dom";

// Components
import CallContainer from '../containers/CallContainer'
import CallsSearchDialogContainer from '../containers/CallsSearchDialogContainer';
import MoreCallsButton from  '../containers/MoreCallsButtonContainer';

// MUI
import { Box, Typography } from '@mui/material';

const ClientCalls = ({
  callsByClientId,
  isFetchingCalls,
  fetchCallsByClientId,
  fetchMoreCallsByClientId,
  searchCalls,
  deleteCallsByClientId,
  filters,
  addMoreCallPeriod,
}) => {
  const routeParams = useParams();

  useEffect(() => {
    fetchCallsByClientId(routeParams.clientId);
  }, [fetchCallsByClientId, routeParams.clientId]);

  const formatDateToFr = (date) => date.toLocaleString('fr-Fr').split(' ')[0]

  // Tri des appels par jour
  const currentCalls = useCallback(() => {
    // affichera uniquement les appels de ce client
    const currentCallsByClientId = callsByClientId.filter(
      ({ clientId }) => clientId === parseInt(routeParams.clientId),
    )

    currentCallsByClientId.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.date.seconds - a.date.seconds;
    });

    return currentCallsByClientId.reduce(
      (acc, curr) => {
        let date = formatDateToFr(new Date(curr.date))
        if (typeof (curr.date) !== 'string') {
          date = formatDateToFr(new Date(curr.date.seconds * 1000))
        }
        const newData = acc[date] !== undefined
          ? [...acc[date], curr]
          : [curr]

        return {
          ...acc,
          [date]: newData,
        }
      },
      {},
    )
  }, [callsByClientId, routeParams.clientId])

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Dialog recherche avancée d'appel */}
      <CallsSearchDialogContainer
        searchCalls={searchCalls}
        fetchCalls={() => fetchCallsByClientId(routeParams.clientId)}
        defaultFilters={{ clientId: routeParams.clientId }}
        deleteCalls={deleteCallsByClientId}
      />
      {currentCalls() &&
        Object.keys(currentCalls()).map((dayCalls) => (
          <Box key={dayCalls}>
            {/* Date des appels */}
            <Typography color="secondary" sx={{ fontWeight: 'bold' }}>{dayCalls === formatDateToFr(new Date()) ? "Aujourd'hui" : dayCalls}</Typography>
            {/* Liste des appels par jour */}
            {
              currentCalls()[dayCalls].map(
                (call) => <CallContainer key={call.id} {...call} />
              )
            }
          </Box>
        )
        )
      }
      {/* more button */}
      <MoreCallsButton
        isFetchingCalls={isFetchingCalls}
        filters={filters}
        addMoreCallPeriod={addMoreCallPeriod}
        searchCalls={searchCalls}
        fetchCalls={() => fetchMoreCallsByClientId(routeParams.clientId)}
      />
    </Box>
  )
}

ClientCalls.propTypes = {
  callsByClientId: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // client Genapi cf Clients
      clientId: PropTypes.number.isRequired
    }).isRequired,
  ).isRequired,
  // récupère les appels par client
  fetchCallsByClientId: PropTypes.func.isRequired,
  // telecharge x (ou x = MORE_CALL) appels supplémentaires depuis firestore
  fetchMoreCallsByClientId: PropTypes.func.isRequired,
  // Si la récupération des appels est en cours
  isFetchingCalls: PropTypes.bool.isRequired,
  // Recherche d'appel
  searchCalls: PropTypes.func.isRequired,
  // Suppression des appels 
  deleteCallsByClientId: PropTypes.func.isRequired,
  // Filters
  filters: PropTypes.object.isRequired,
  // Add more calls 
  addMoreCallPeriod: PropTypes.func.isRequired,
}

export default ClientCalls;
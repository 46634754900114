import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from 'prop-types'
import ClientBody from './ClientBody';
import Loader from "./Loader";
// mui
import { Tooltip, Typography, Box, Popover, Grid } from '@mui/material';
// const
import { PAGE_CLIENT } from '../pages';
import ClickAwayListener from '@mui/base/ClickAwayListener';
// Styles
import { StyledTypo, StyledInlinePaddingGrid } from "../styles/ClientsStyle";
import FolderIcon from '@mui/icons-material/Folder';

const TypographyTooltip = ({
  typographySx,
  children,
  direction,
  client,
  fullClients,
  isFetchingFullClient,
  fetchFullClient,
  folders,
  isFetchingFolders,
  fetchFolders,
  saveTabIndex,
}) => {
  const textElementRef = useRef();
  const [isOverflowed, setIsOverflow] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [isHoveredFolder, setHoveredFolder] = useState(false);
  const [textDecoration, setTextDecoration] = useState('none');
  const [fontWeight, setFontWeight] = useState('550');
  const [textDecorationFolder, setTextDecorationFolder] = useState('none');
  const [fontWeightFolder, setFontWeightFolder] = useState('550');
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, [children]);

  // Gestion FontWeight et textdecoration on Hover
  useEffect(() => {
    if (isHovered) {
      if ('internal' !== direction || ('internal' === direction && client?.denomination)) {
        setTextDecoration('underline');
        setFontWeight('700');
      }
    } else {
      setTextDecoration('none');
      setFontWeight('550');
    }
  }, [isHovered, direction, client?.denomination]);

  // Gestion FontWeight et textdecoration on Hover pour les dossiers
  useEffect(() => {
    if (isHoveredFolder) {
      if ('internal' !== direction || ('internal' === direction && client?.denomination)) {
        setTextDecorationFolder('underline');
        setFontWeightFolder('700');
      }
    } else {
      setTextDecorationFolder('none');
      setFontWeightFolder('550');
    }
  }, [isHoveredFolder, direction, client?.denomination]);

  // Récuperation du fullClient
  const getSelectedClient = useCallback(() => {
    if (!fullClients) {
      return undefined;
    }
    return fullClients.filter((fullClient) => fullClient.id === parseInt(client?.id))[0]
  }, [fullClients, client?.id])

  // Gestion Popover
  const handleMouseEnter = (e) => {
    setHovered(true);
  };
  const openPopOver = (e) => {
    if (client?.id) {
      fetchFullClient(client.id)
    }
    setAnchorEl(e.currentTarget);
    if ('internal' !== direction || ('internal' === direction && client?.denomination)) {
      fetchFolders(client.id);
    }
    e.stopPropagation();
  }
  const handleMouseLeave = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  // Redirection vers /client
  const handleOnClickClient = (e) => {
    if ('internal' !== direction || ('internal' === direction && client?.denomination)) {
      if (PAGE_CLIENT === location.pathname) return
      saveTabIndex(`/clients/${client.id}`, 0);
      navigate(`/clients/${client.id}`);
      e.stopPropagation();
    }
  }

  // Redirection vers /client/dossier
  const handleOnClickFolder = (e) => {
    if ('internal' !== direction || ('internal' === direction && client?.denomination)) {
      if (PAGE_CLIENT === location.pathname) return
      saveTabIndex(`/clients/${client.id}`, 2);
      navigate(`/clients/${client.id}`);
      e.stopPropagation();
    }
  }

  const fullClient = getSelectedClient();

  return (
    <>
      <ClickAwayListener
        onClickAway={handleMouseLeave}
      >
        <Tooltip
          title={children}
          disableHoverListener={!isOverflowed}
          arrow
        >

          <Typography
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setHovered(false)}
            onClick={openPopOver}
            variant='body1'
            noWrap
            ref={textElementRef}
            sx={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden !important',
              textOverflow: 'ellipsis',
              fontWeight: fontWeight,
              textDecoration: textDecoration,
              ...typographySx,
            }}
          >
            {children}
          </Typography>

        </Tooltip>
      </ClickAwayListener>
      {
        (client && ('internal' !== direction || ('internal' === direction && client?.denomination))) && (
          <Box>
            <Popover
              onClick={handleMouseLeave}
              id="mouse-over-popover"
              open={open}
              anchorEl={anchorEl}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handleMouseLeave}
            >
              <Box
                sx={{
                  padding: 3,
                  boxShadow: 4,
                  width: ['300px', '350px', '500px', '800px'],
                  border: 'solid 2px',
                  borderColor: 'secondary.main',
                  overflowY: 'auto',
                  maxHeight: '60vh',
                  minHeight: '30vh',
                }}
              >
                {
                  !isFetchingFullClient && !isFetchingFolders && fullClient
                    ? (
                      <>
                        <Box sx={{ minWidth: "100%" }} display="flex"
                          justifyContent="center"
                          alignItems="center">
                          <Typography
                            variant="h1"
                            color={'primary'}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={handleOnClickClient}
                            sx={{
                              fontWeight: fontWeight,
                              textDecoration: textDecoration,
                              cursor: 'pointer',
                            }}
                          >{client.denomination}</Typography>
                        </Box>
                        <ClientBody client={fullClient} />
                        {
                          isFetchingFolders ?
                            (<Loader size={100} color="secondary" />)
                            :
                            (
                              <>
                                <Box sx={{ minWidth: "100%" }}>
                                  <Typography
                                    variant="h1"
                                    color={'primary'}
                                    alignItems='center'
                                    textAlign='center'
                                    onClick={handleOnClickFolder}
                                    onMouseEnter={() => setHoveredFolder(true)}
                                    onMouseLeave={() => setHoveredFolder(false)}
                                    sx={{
                                      fontWeight: fontWeightFolder,
                                      textDecoration: textDecorationFolder,
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Dossiers
                                  </Typography>
                                </Box>
                                {
                                  (folders && Object.keys(folders).length !== 0) ?

                                    folders.map((folder) => (
                                      <Box key={folder.id}>
                                        {folder?.intitule ?
                                          <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ paddingTop: "5px" }}>
                                            <FolderIcon />
                                            <StyledTypo variant="h3" color={'primary.light'}>{folder.intitule}</StyledTypo>
                                          </Box>
                                          :
                                          null
                                        }
                                        {/* Informations Principales */}
                                        <Box sx={{paddingTop: '0.1rem'}}>
                                          <StyledTypo variant="body1" color={'primary.light'} sx={{fontWeight:'bold', paddingLeft: '1.75rem'}}>Informations principales</StyledTypo>
                                        </Box>
                                        <Grid container justifyContent="space-between">
                                          {/* Nom */}
                                          {folder?.nom ?
                                            <StyledInlinePaddingGrid item xs={12} lg={6}>
                                              <StyledTypo variant="body1" color={'primary.light'}>Nom </StyledTypo>
                                              <StyledTypo variant="body1" color={'secondary'}>{folder.nom}</StyledTypo>
                                            </StyledInlinePaddingGrid>
                                            :
                                            null
                                          }
                                          {/* Type */}
                                          {folder?.typeNature ?
                                            <StyledInlinePaddingGrid item xs={12} lg={6}>
                                              <StyledTypo variant="body1" color={'primary'}>Type </StyledTypo>
                                              <StyledTypo variant="body1" color={'secondary'}>{folder.typeNature}</StyledTypo>
                                            </StyledInlinePaddingGrid>
                                            :
                                            null
                                          }
                                          {/* Etat dossier */}
                                          {folder?.valeurMetierEtat?.intitule ?
                                            <StyledInlinePaddingGrid item xs={12} lg={6}>
                                              <StyledTypo variant="body1" color={'primary'}>État du dossier </StyledTypo>
                                              <StyledTypo variant="body1" color={'secondary'}>{folder.valeurMetierEtat?.intitule}</StyledTypo>
                                            </StyledInlinePaddingGrid>
                                            :
                                            null
                                          }

                                          {/* Montant Previsionnel */}
                                          {folder?.montantPrevisionnel ?
                                            <StyledInlinePaddingGrid item xs={12} lg={6}>
                                              <StyledTypo variant="body1" color={'primary'}>Montant prévisionnel </StyledTypo>
                                              <StyledTypo variant="body1" color={'secondary'}>{folder.montantPrevisionnel}</StyledTypo>
                                            </StyledInlinePaddingGrid>
                                            :
                                            null
                                          }
                                          {/* Service */}
                                          {folder?.service ?
                                            <StyledInlinePaddingGrid item xs={12} lg={6}>
                                              <StyledTypo variant="body1" color={'primary'}>Service </StyledTypo>
                                              <StyledTypo variant="body1" color={'secondary'}>{folder.service.intitule}</StyledTypo>
                                            </StyledInlinePaddingGrid>
                                            :
                                            null
                                          }
                                        </Grid>
                                        {/* Intervenants */}
                                        {(folder.notaire || folder.clerc || folder.clercSecondaire || folder.secretaire) ?
                                          <>
                                            <Box sx={{paddingTop: '0.4rem'}}>
                                              <StyledTypo variant="body1" color={'primary.light'} sx={{padding: '1.75rem', fontWeight:'bold'}}>Intervenants</StyledTypo>
                                            </Box>
                                            <Grid container justifyContent="space-between">
                                              {/* Notaire */}
                                              {folder?.notaire?.identite ?
                                                <StyledInlinePaddingGrid item xs={12} lg={6}>
                                                  <StyledTypo variant="body1" color={'primary'}>Notaire </StyledTypo>
                                                  <StyledTypo variant="body1" color={'secondary'}>{folder.notaire.identite}</StyledTypo>
                                                </StyledInlinePaddingGrid>
                                                :
                                                null
                                              }
                                              {/* clerc */}
                                              {folder?.clerc?.identite ?
                                                <StyledInlinePaddingGrid item xs={12} lg={6}>
                                                  <StyledTypo variant="body1" color={'primary'}>Clerc </StyledTypo>
                                                  <StyledTypo variant="body1" color={'secondary'}>{folder.clerc.identite}</StyledTypo>
                                                </StyledInlinePaddingGrid>
                                                :
                                                null
                                              }
                                              {/* clerc secondaire*/}
                                              {folder?.clercSecondaire?.identite ?
                                                <StyledInlinePaddingGrid item xs={12} lg={6}>
                                                  <StyledTypo variant="body1" color={'primary'}>Clerc secondaire </StyledTypo>
                                                  <StyledTypo variant="body1" color={'secondary'}>{folder.clercSecondaire.identite}</StyledTypo>
                                                </StyledInlinePaddingGrid>
                                                :
                                                null
                                              }
                                              {/* secretaire*/}
                                              {folder?.secretaire?.identite ?
                                                <StyledInlinePaddingGrid item xs={12} lg={6}>
                                                  <StyledTypo variant="body1" color={'primary'}>Secretaire </StyledTypo>
                                                  <StyledTypo variant="body1" color={'secondary'}>{folder.secretaire.identite}</StyledTypo>
                                                </StyledInlinePaddingGrid>
                                                :
                                                null
                                              }

                                            </Grid>
                                          </>
                                          :
                                          null
                                        }

                                      </Box>
                                    ))

                                    :
                                    <StyledTypo variant="h3" color={'primary.light'}>Pas de dossier !</StyledTypo>
                                }

                              </>
                            )
                        }
                      </>
                    )
                    :
                    (
                      <Box display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="300px">
                        <Loader size={100} color="secondary" />
                      </Box>
                    )
                }

              </Box>
            </Popover>
          </Box>
        )
      }
    </>
  );
};

TypographyTooltip.propTypes = {
  // pour modifier le style de la Typography
  typographySx: PropTypes.object,
  // indique de quel type sera le composant h1, div, span etc...
  component: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  client: PropTypes.exact({
    adresse: PropTypes.exact({
      commune: PropTypes.exact({
        codeInsee: PropTypes.string,
        codePostal: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
      }),
      complementAdresse: PropTypes.string.isRequired,
      extensionNumero: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string,
      }),
      numero: PropTypes.string.isRequired,
      pays: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeVoie: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      voie: PropTypes.string.isRequired,
    }),
    civilite: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    dateCreation: PropTypes.string,
    dateDeces: PropTypes.string,
    dateDernierAcces: PropTypes.string,
    dateModification: PropTypes.string,
    dateNaissance: PropTypes.exact({
      jour: PropTypes.number,
      mois: PropTypes.number,
      annee: PropTypes.number.isRequired,
    }),
    denomination: PropTypes.string.isRequired,
    email: PropTypes.string,
    etatMarital: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    formeSociete: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    numero: PropTypes.string,
    registreCommerce: PropTypes.string,
    siren: PropTypes.string,
    telephones: PropTypes.arrayOf(
      PropTypes.exact({
        typeTelephone: PropTypes.string.isRequired,
        numero: PropTypes.string.isRequired,
      }),
    ),
    typeClient: PropTypes.string,
    dateRecuperation: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }),
    tenantId: PropTypes.string,
  }).isRequired,
  direction: PropTypes.string.isRequired,
  fetchFolders: PropTypes.func.isRequired,
  fetchFullClient: PropTypes.func.isRequired,
  folders: PropTypes.arrayOf(
    PropTypes.exact({
      clerc: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clercSecondaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clientId: PropTypes.number.isRequired,
      dateCreation: PropTypes.node.isRequired,
      dateDernierAcces: PropTypes.node,
      dateModification: PropTypes.node.isRequired,
      dateOuvertureDossier: PropTypes.node.isRequired,
      datePrevisionnelleSignature: PropTypes.node,
      id: PropTypes.number.isRequired,
      idDossierParent: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
      montantPrevisionnel: PropTypes.number,
      nature: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      notaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      numero: PropTypes.string.isRequired,
      secretaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      service: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeNature: PropTypes.string.isRequired,
      valeurMetierEtat: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  fullClients: PropTypes.arrayOf(
    PropTypes.exact({
      actions: PropTypes.arrayOf(
        PropTypes.exact({
          nom: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
          method: PropTypes.string.isRequired,
          titre: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          schemaUrl: PropTypes.string.isRequired,
        }),
      ),
      adresse: PropTypes.exact({
        commune: PropTypes.exact({
          codeInsee: PropTypes.string,
          codePostal: PropTypes.string.isRequired,
          nom: PropTypes.string.isRequired,
        }),
        complementAdresse: PropTypes.string.isRequired,
        extensionNumero: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string,
        }),
        numero: PropTypes.string.isRequired,
        pays: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        typeVoie: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        voie: PropTypes.string.isRequired,
      }),
      civilite: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      dateCreation: PropTypes.string.isRequired,
      dateDeces: PropTypes.string,
      dateDernierAcces: PropTypes.string,
      dateModification: PropTypes.string.isRequired,
      dateNaissance: PropTypes.exact({
        jour: PropTypes.number,
        mois: PropTypes.number,
        annee: PropTypes.number.isRequired,
      }),
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      etatMarital: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      formeSociete: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      liens: PropTypes.arrayOf(
        PropTypes.exact({
          href: PropTypes.string.isRequired,
          relation: PropTypes.string.isRequired,
          verbe: PropTypes.string.isRequired,
        }),
      ),
      numero: PropTypes.string.isRequired,
      registreCommerce: PropTypes.string,
      siren: PropTypes.string,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      typeClient: PropTypes.string.isRequired,
      dateRecuperation: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  isFetchingFolders: PropTypes.bool.isRequired,
  isFetchingFullClient: PropTypes.bool.isRequired,
}

export default TypographyTooltip;
import { connect } from "react-redux";
// Components
import CustomSnackbar from "../components/CustomSnackbar";
// actions
import { cleanSnackbar } from "../redux/snackbar/actionSnackbar";

const mapStateToProps = (state) => ({
  snackbar: state.snackbar.snackbar,
})

const mapDispatchToProps = (dispatch) => ({
  cleanSnackbar: () => dispatch(cleanSnackbar()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomSnackbar)
/* eslint-disable no-fallthrough */
// actions
import {
  saveEmail,
  saveUuid,
  saveServer,
  saveTenantUuid,
  saveError,
  saveIsAuthenticationLoading,
  SIGN_IN_WAZO,
  SIGN_IN_WAZO_WITH_CONTEXT,
  saveWazoToken,
  saveRefreshToken,
  saveExpiresAt,
  saveIsConnected,
  saveIsConnectingWithContext,
  CHECK_IS_VALID_WAZO_TOKEN,
  CONNECT_TO_WAZO_APP,
  CHECK_IS_MOST_RECENT_VERSION,
  saveIsCheckingWazoToken,
  saveIsCheckingIsMostRecentVersion,
  LOG_OUT,
  logOut,
  LOG_IN,
  logIn,
  saveIsDisconnecting,
  saveIsConnectingToWazoApp,
  saveIsDisconnectWazoApp,
} from "./actionLogin";
import { connectWebsocketWazo, disconnectWebsocketWazo } from "../websocketWazo/actionWebsocketWazo";
import { connectWebsocketFirebaseHistoryCallCollaboratorUuid, connectWebsocketFirebaseCalls } from "../websocketFirebase/actionWebsocketFirebase";
import {
  deleteCalls,
  deleteHistoryCalls,
  deleteCallsByClientId,
  deleteCallsByCollaboratorUuid,
  deleteHistoryCallMessages,
} from "../calls/actionCalls";
import {
  deleteClients,
  deleteFolders,
  deleteFiles,
  deleteBinders,
  saveGenapiToken,
} from "../clients/actionClients";
import { deleteUsers } from "../users/actionUsers";

import { App } from '@wazo/euc-plugins-sdk';

// consts
const EXPIRED_SESSION_TIME = 604800; // 7 jours en seconde
const CLIENT_ID = "avelia-link";
let app;
try {
  app = new App();
  await app.initialize();
} catch (error) {
  console.log(error);
  app = null;
}

const middlewareLogin = (store) => (next) => (action) => {
  switch (action.type) {
    case SIGN_IN_WAZO:
      const checkIsEmptyValue = (valueName, fieldName, value) => {
        if ('' === value) {
          error[valueName] = `Le champ ${fieldName} est obligatoire`;
        }
      }

      const authToWazo = async () => {
        try {
          const server = action.server.trim();
          const email = action.email.trim()

          const payload = JSON.stringify({
            client_id: CLIENT_ID,
            expiration: EXPIRED_SESSION_TIME,
            access_type: "offline"
          });
          
          const rawSession = await fetch(`https://${server}/api/auth/0.1/token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Basic ' + btoa(email + ":" + action.password.trim())
            },
            body: payload,
          })

          const { reason, status_code, data } =  await rawSession.json()

          if (!rawSession.ok) {
            const error = new Error();
            error.message = reason.join(', ') || 'Something went wrong';
            error.status = status_code || '';

            throw error;
          }
          
          const userUuid = data.auth_id;
          const tenantUuid = data.metadata.tenant_uuid;
          const expireAt = data.expires_at;
          
          // lance la connexion aux websocket wazo et firebase
          store.dispatch(logIn(
            data.token,
            data.refresh_token,
            userUuid,
            tenantUuid,
            expireAt,
            email,
            server,
          ));
        } catch (error) {
          console.log({ error });
          if (error instanceof TypeError) {
            // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypeError
            store.dispatch(saveError({ 'server': 'Erreur lors de la connexion au serveur : vérifier votre connexion internet ainsi que le nom de domaine saisi' }))
          } else if (error instanceof Error) {
            if (401 === error.status) {
              store.dispatch(saveError({ 'authentication': "L'identifiant ou le mot de passe sont incorrectes" }))
            } else {
              store.dispatch(saveError({ 'authentication': "Une erreur inattendue s'est produite durant l'authentification" }))
            }
          }
          store.dispatch(saveIsAuthenticationLoading(false))
        }
      }
      const error = {};
      store.dispatch(saveError({}));
      store.dispatch(saveIsAuthenticationLoading(true))
      checkIsEmptyValue('server', 'serveur', action.server)
      checkIsEmptyValue('email', 'email', action.email)
      checkIsEmptyValue('password', 'password', action.password)

      if (0 === Object.keys(error).length) {
        authToWazo()
      } else {
        store.dispatch(saveError(error))
        store.dispatch(saveIsAuthenticationLoading(false))
      }

      next(action);
      break;

    case SIGN_IN_WAZO_WITH_CONTEXT:
      store.dispatch(saveIsConnectingWithContext(true));

      const signInWazoWithContext = async () => {
        // si l'application est lancé depuis wazo desktop app existera
        if (app) {  
          const context = app.getContext();
          const user = context.user;

          // lance la connexion aux websocket wazo et firebase
          store.dispatch(logIn(
            user.token,
            user.refresh_token,
            user.profile.id,
            user.tenantUuid,
            user.expiresAt,
            user.profile.email,
            user.host,
          ));
        }
        // sinon l'authentification est remise à zéro afin d'etre lancé depuis /login 
        else {
          store.dispatch(logOut());
        }

        store.dispatch(saveIsConnectingWithContext(false));
      }
      signInWazoWithContext();

      next(action);
      break;
    
    // permet de verifier le si token wazo est toujours valide
    // si ok => lance logIn()
    // si ko => lance logOut()
    case CHECK_IS_VALID_WAZO_TOKEN:
      const checkIsValidWazoToken = async (token) => {
        try {
          // si le token n'existe pas leve une erreur
          if ('' === token || undefined === token) {
            const error = new Error();
            error.message = 'Token non présent';
            error.status = 400;
  
            throw error;
          }

          // test le token via l'api Wazo
          const server = store.getState().login.server;
          const rawSession = await fetch(`https://${server}/api/auth/0.1/token/${token}`, {
            method: "HEAD",
            headers: {
              "accept": "application/json",
              'X-Auth-Token': token,
            },
          })
          const response = await rawSession;

          // si le token est invalide leve une erreur
          if (404 === response.status) {
            const error = new Error();
            error.message = 'Token non valide';
            error.status = response.status;
  
            throw error;
          }
          // sinon la connexion se lance
          store.dispatch(logIn(
            store.getState().login.token,
            store.getState().login.refreshToken,
            store.getState().login.uuid,
            store.getState().login.tenantUuid,
            store.getState().login.expiresAt,
            store.getState().login.email,
            store.getState().login.server,
          ));
        } catch (error) {
          console.log({ error })
          if (error instanceof TypeError) {
            // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/TypeError
            console.log('authentication: Token non présent')
          } else if (error instanceof Error) {
            if (404 === error.status) {
              store.dispatch(saveError({ 'authentication': "La connexion a expiré, veuillez vous reconnecter"}))
            } 
            if (400 === error.status) {
              console.log('authentication: Token non présent')
            }
            else {
              store.dispatch(saveError({ 'authentication': "Une erreur inattendue s'est produite durant l'authentification" }))
            }
          }
          store.dispatch(logOut());
        }
        finally {
          store.dispatch(saveIsCheckingWazoToken(false))
        }
      }
      checkIsValidWazoToken(store.getState().login.token);  
    
      next(action);
      break;

    case CHECK_IS_MOST_RECENT_VERSION:
      store.dispatch(saveIsCheckingIsMostRecentVersion(true));

      const checkVersion = () => {
        const liveVersion = process.env.REACT_APP_VERSION;
        const currentVersion = localStorage.getItem('version');
    
        if (liveVersion !== currentVersion) {
          console.log(`Mise à jour effectué en version ${liveVersion}`)
          localStorage.setItem('version', liveVersion);
          store.dispatch(logOut());
        }

        store.dispatch(saveIsCheckingIsMostRecentVersion(false));
      }

      checkVersion();

      next(action);
      break;

    case CONNECT_TO_WAZO_APP:
      if (app) {
        app.onIframeMessage = (msg) => {
          if(msg.event_id === 'response_is_first_connection') {
              console.log('response_is_first_connection CONNECT_TO_WAZO_APP');
              store.dispatch(saveIsConnectingToWazoApp(false))
          }

          if(msg.event_id === 'response_is_not_first_connection') {
            console.log('response_is_not_first_connection CONNECT_TO_WAZO_APP')
            store.dispatch(saveIsConnectingToWazoApp(false));
          }

          if(msg.event_id === 'on_load_app') {
            console.log('on_load_app CONNECT_TO_WAZO_APP');
            app.sendMessageToBackground(
              {
                  event_id: 'ask_is_first_connection',
              }, 
              "*"
            );
          }
        };

        app.sendMessageToBackground(
          {
              event_id: 'ask_is_first_connection',
          }, 
          "*"
        );

        app.onLogout = () => {
          // permet d'eviter la reconnexion avec SIGN_IN_WITH_CONTEXT
          store.dispatch(saveIsDisconnectWazoApp(true));
          store.dispatch(logOut());
        }
  
        app.onNewSession = (session) => {
          // TODO : sauvegarder le résultat de la session dans le state
        }
      } else {
        store.dispatch(saveIsConnectingToWazoApp(false));
      }

      next(action);
      break;

    case LOG_OUT:
      store.dispatch(saveIsDisconnecting(true));

      const clearBrowserCache = () => {
        // Check if the caches API is available
        if ('caches' in window) {
          // Open all cache names
          caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
              // Delete each cache
              caches.delete(cacheName);
            });
          });
        }
      }
    
      const clearStore = () => {
        store.dispatch(deleteCalls());
        store.dispatch(deleteHistoryCalls());
        store.dispatch(deleteCallsByClientId());
        store.dispatch(deleteCallsByCollaboratorUuid());
        store.dispatch(deleteClients());
        store.dispatch(deleteUsers());
        store.dispatch(deleteFolders());
        store.dispatch(deleteFiles());
        store.dispatch(deleteBinders());
        store.dispatch(deleteHistoryCallMessages())
      }
    
      const clearAuthentication = () => {
        store.dispatch(saveRefreshToken(''));
        store.dispatch(saveUuid(''));
        store.dispatch(saveTenantUuid(''));
        store.dispatch(saveExpiresAt(''));
        store.dispatch(saveWazoToken(''));
        store.dispatch(saveGenapiToken(''));
      }
    
      const clearLocalStorage = () => {
        const reducerNames = Object.keys(store.getState())

        reducerNames.forEach((reducerName) => {
          localStorage.removeItem(`persist:${reducerName}`);
        })

        localStorage.removeItem("persist:root");
      }

      const logout = () => {
        store.dispatch(saveIsConnected(false));
        store.dispatch(disconnectWebsocketWazo());
        clearBrowserCache();
        clearStore();
        clearAuthentication();
        clearLocalStorage();
        store.dispatch(saveIsDisconnecting(false));
      }

      logout()

      
      next(action);
      break;

    // permet de se connecter au websocket wazo et firebase
    case LOG_IN:
      // se connecte au websocket Wazo
      const initWebsocket = () => {
        store.dispatch(connectWebsocketWazo())
      }

      // se connecte au websocket firebase
      const initFirebase = (uuid) => {
        store.dispatch(connectWebsocketFirebaseHistoryCallCollaboratorUuid(uuid));
        store.dispatch(connectWebsocketFirebaseCalls(uuid));
      };

      // state présent dans requireAuth permetant de lancer le tunnel d'authentification
      // si la connexion à lieu ils doivent etre à false
      const cleanAuthenticationState = () => {
        store.dispatch(saveIsConnectingWithContext(false));
        store.dispatch(saveIsCheckingWazoToken(false));
        store.dispatch(saveIsCheckingIsMostRecentVersion(false));
        store.dispatch(saveIsDisconnecting(false));
        store.dispatch(saveIsConnectingToWazoApp(false));
      };

      // met à jour les state d'authentification
      const authenticate = () => {
        store.dispatch(saveWazoToken(action.token))
        store.dispatch(saveRefreshToken(action.refreshToken))
        store.dispatch(saveUuid(action.uuid))
        store.dispatch(saveTenantUuid(action.tenantUuid))
        store.dispatch(saveExpiresAt(action.expireAt))
        store.dispatch(saveEmail(action.email))
        store.dispatch(saveServer(action.server))
      }

      const cleanError = () => {
       const { authentication, ...errors } = store.getState().login.error;
       store.dispatch(saveError(errors));
      }

      const login = () => {
        authenticate();
        initWebsocket();
        initFirebase(action.uuid);
        cleanAuthenticationState();
        cleanError();

        store.dispatch(saveIsConnected(true))
      }

      // la connexion se fait via CHECK_IS_VALID_WAZO_TOKEN, SIGN_IN_WAZO_WITH_CONTEXT et SIGN_IN_WAZO
      // si un des 3 à déjà était lancé alors il n'est pas nécéssaire de relancer login()
      if (!store.getState().login.isConnected) {
        login();
      }

      next(action);
      break;

    default: next(action)
  }
}

export default middlewareLogin;
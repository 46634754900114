import { useCallback, useEffect } from "react";
import PropTypes from 'prop-types'
import { useLocation } from "react-router-dom";
import { useSnackbar } from 'notistack';

// MUI
import {
  Box,
  Typography,
  IconButton,
} from '@mui/material';
// Icons
import CloseIcon from '@mui/icons-material/Close';

const CustomSnackbar = ({
  cleanSnackbar,
  snackbar,
}) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const Snackbar = ({ message }) => (
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'none',
      }}>
        <Typography component="span" variant="body1" sx={{ pl: 2, pr: 5 }}>{message}</Typography>
      </Box>
  );

  const handleCancelDownload =  useCallback(() => (
    cleanSnackbar()
  ), [cleanSnackbar])
  
  useEffect(() => {
    handleCancelDownload()
  }, [handleCancelDownload, location.pathname])

  const action = useCallback(() => (
    <IconButton onClick={handleCancelDownload} sx={{ color: 'white', padding: 1}} size='small' aria-label="cancel update">
      <CloseIcon />
    </IconButton>
  ), [handleCancelDownload]);

  useEffect(() => {
    const getVariant = (currVariant) => {
      const variantList = {
        [currVariant]: currVariant,
        'call': 'info'
      }
      return variantList[currVariant];
    }
    if (snackbar?.message) {
      enqueueSnackbar(
        <Snackbar message={snackbar.message} />,
        { 
          variant: getVariant(snackbar.variant),
          anchorOrigin: { vertical: 'top', horizontal: 'center'},
          style: { justifyContent: 'center' },
          action,
        },
      )
    }
  }, [action, enqueueSnackbar, snackbar.message, snackbar.variant])

  return (
    <></>
  )
}

CustomSnackbar.propTypes = {
  // reinitialise la snackbar
  cleanSnackbar: PropTypes.func.isRequired,
  // affiche une snackbar
  snackbar: PropTypes.exact({
    message: PropTypes.string,
    variant: PropTypes.string,
    call: PropTypes.object,
  }),
}

export default CustomSnackbar;
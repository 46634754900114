import {
  REPLACE_CLIENTS,
  SAVE_CLIENTS,
  DELETE_CLIENTS,
  SAVE_FULL_CLIENT,
  SAVE_GENAPI_TOKEN,
  SAVE_FOLDERS,
  DELETE_FOLDERS,
  SAVE_IS_FETCHING_FOLDERS,
  SAVE_IS_FETCHING_CLIENTS,
  SAVE_FILES,
  SAVE_FILE,
  DELETE_FILES,
  SAVE_IS_FETCHING_FILES,
  SAVE_IS_DISPLAYED_CLIENT_FOLDER_DIALOG,
  SAVE_FOLDER_DISPLAYED,
  SAVE_IS_UPLOADING_FILE,
  SAVE_BINDERS,
  DELETE_BINDERS,
  SAVE_SELECTED_BINDER,
  SAVE_IS_FETCHING_FULL_CLIENT,
  SAVE_FETCHING_FULL_CLIENT_ERROR,
} from "./actionClients";

const initialStateTest = {
  clients: [],
  accessToken: '',
  expires_in: 0,
  refresh_token: '',
  token_type: '',
  folders: [],
  isFetchingFolders: false,
  isFetchingClients: false,
  files: [],
  downloadedFile: {},
  isFetchingFiles: false,
  isDisplayedClientFolderDialog: false,
  folderDisplayed: {},
  isUploadingFile: false,
  binders: [],
  selectedBinder: '',
  isFetchingFullClient: false,
  fetchingFullClient: '',
  fullClients: [],
  isFetchingClientError: false,
}

const clientsReducer = (state = initialStateTest, action) => {

  switch (action.type) {
    case REPLACE_CLIENTS:
      return {
        ...state,
        clients: action.data,
      }

    case SAVE_CLIENTS: {
      return {
        ...state,
        clients: [
          ...state.clients,
          ...action.data,
        ]
      }
    }

    case DELETE_CLIENTS: {
      return {
        ...state,
        clients: []
      }
    }

    case SAVE_FULL_CLIENT: {
      return {
        ...state,
        fullClients: [
          ...state.fullClients,
          action.fullClient,
        ]
      }
    }

    case SAVE_GENAPI_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
        expires_in: action.expires_in,
        refresh_token: action.refresh_token,
        token_type: action.token_type,
      }
    case SAVE_FOLDERS:
      return {
        ...state,
        folders: action.data,
      }

    case DELETE_FOLDERS: {
      return {
        ...state,
        folders: [],
      }
    }

    case SAVE_IS_FETCHING_FOLDERS:
      return {
        ...state,
        isFetchingFolders: action.isFetchingFolders,
      }

    case SAVE_IS_FETCHING_CLIENTS:
      return {
        ...state,
        isFetchingClients: action.isFetchingClients,
      }

    case SAVE_FILES:
      return {
        ...state,
        files: action.folderFiles,
      }

    case SAVE_FILE:
      return {
        ...state,
        downloadedFile: action.downloadedFile,
      }

    case DELETE_FILES: {
      return {
        ...state,
        files: [],
        downloadedFile: {},
      }
    }

    case SAVE_IS_FETCHING_FILES:
      return {
        ...state,
        isFetchingFiles: action.isFetchingFiles,
      }

    case SAVE_IS_DISPLAYED_CLIENT_FOLDER_DIALOG:
      return {
        ...state,
        isDisplayedClientFolderDialog: action.isDisplayedClientFolderDialog,
      }

    case SAVE_FOLDER_DISPLAYED:
      return {
        ...state,
        folderDisplayed: action.folderDisplayed,
      }

    case SAVE_IS_UPLOADING_FILE:
      return {
        ...state,
        isUploadingFile: action.isUploadingFile,
      }

    case SAVE_BINDERS:
      return {
        ...state,
        binders: action.binders,
      }

    case DELETE_BINDERS: {
      return {
        ...state,
        binders: [],
      }
    }

    case SAVE_SELECTED_BINDER:
      return {
        ...state,
        selectedBinder: action.selectedBinder,
      }

    case SAVE_IS_FETCHING_FULL_CLIENT:
      return {
        ...state,
        isFetchingFullClient: action.isFetchingFullClient,
      }

    case SAVE_FETCHING_FULL_CLIENT_ERROR:
      return {
        ...state,
        isFetchingClientError: action.isFetchingClientError,
      }

    default: return state
  }
}
export default clientsReducer;
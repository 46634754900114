export const SAVE_CALLS = 'SAVE_CALLS';
export const REPLACE_CALLS = 'REPLACE_CALLS';
export const SAVE_CALL = 'SAVE_CALL';
export const UPDATE_CALL = 'UPDATE_CALL';
export const UPDATE_CALL_ID = 'UPDATE_CALL_ID';
export const DELETE_CALL = 'DELETE_CALL';
export const POST_CALL = 'POST_CALL';
export const FETCH_CALLS = 'FETCH_CALLS';
export const FETCH_MORE_CALLS = 'FETCH_MORE_CALLS';
export const SEARCH_CALLS = 'SEARCH_CALLS';
export const SAVE_IS_FETCHING_CALLS = 'SAVE_IS_FETCHING_CALLS';
export const SAVE_IS_FETCHING_MORE_CALLS = 'SAVE_IS_FETCHING_MORE_CALLS';
export const SAVE_HISTORY_CALLS = 'SAVE_HISTORY_CALLS';
export const SAVE_HISTORY_CALL = 'SAVE_HISTORY_CALL';
export const UPDATE_HISTORY_CALL = 'UPDATE_HISTORY_CALL';
export const POST_HISTORY_CALL = 'POST_HISTORY_CALL';
export const FETCH_HISTORY_CALLS = 'FETCH_HISTORY_CALLS';
export const SAVE_CALLER = 'SAVE_CALLER';
export const SAVE_IS_DISPLAYED_CALL_HISTORY_MODAL = 'SAVE_IS_DISPLAYED_CALL_HISTORY_MODAL'
export const SAVE_IS_DISPLAYED_CALL_CLIENT_DIALOG = 'SAVE_IS_DISPLAYED_CALL_CLIENT_DIALOG'
export const FETCH_CALLS_BY_COLLABORATOR_UUID = 'FETCH_CALLS_BY_COLLABORATOR_UUID'
export const SAVE_CALLS_BY_COLLABORATOR_UUID = 'SAVE_CALLS_BY_COLLABORATOR_UUID'
export const SAVE_SEARCHED_CALLS_BY_COLLABORATOR_UUID = 'SAVE_SEARCHED_CALLS_BY_COLLABORATOR_UUID'
export const UPDATE_CALL_BY_COLLABORATOR_UUID = 'UPDATE_CALL_BY_COLLABORATOR_UUID'
export const SAVE_CALLS_BY_COLLABORATOR_UUID_LAST_VISIBLE = 'SAVE_CALLS_BY_COLLABORATOR_UUID_LAST_VISIBLE'
export const SAVE_SEARCH_CALLS_BY_UUID_LAST_VISIBLE = 'SAVE_SEARCH_CALLS_BY_UUID_LAST_VISIBLE'
export const DELETE_CALL_BY_COLLABORATOR_UUID = 'DELETE_CALL_BY_COLLABORATOR_UUID'
export const REPLACE_CALLS_BY_COLLABORATOR_UUID = 'REPLACE_CALLS_BY_COLLABORATOR_UUID'
export const REPLACE_SEARCHED_CALLS_BY_COLLABORATOR_UUID = 'REPLACE_SEARCHED_CALLS_BY_COLLABORATOR_UUID'
export const SAVE_HISTORY_CALL_MESSAGE = 'SAVE_HISTORY_CALL_MESSAGE'
export const SAVE_BLANK_HISTORY_CALL_MESSAGE = 'SAVE_BLANK_HISTORY_CALL_MESSAGE'
export const POST_HISTORY_CALL_MESSAGE = 'POST_HISTORY_CALL_MESSAGE'
export const REMOVE_HISTORY_CALL_MESSAGE = 'REMOVE_HISTORY_CALL_MESSAGE'
export const DELETE_HISTORY_CALL_MESSAGE = 'DELETE_HISTORY_CALL_MESSAGE'
export const FETCH_CALLS_BY_CLIENT_ID = 'FETCH_CALLS_BY_CLIENT_ID';
export const FETCH_MORE_CALLS_BY_CLIENT_ID = 'FETCH_MORE_CALLS_BY_CLIENT_ID';
export const SAVE_CALLS_BY_CLIENT_ID = 'SAVE_CALLS_BY_CLIENT_ID';
export const UPDATE_CALL_BY_CLIENT_ID = 'UPDATE_CALL_BY_CLIENT_ID';
export const REPLACE_CALLS_BY_CLIENT_ID = 'REPLACE_CALLS_BY_CLIENT_ID';
export const SAVE_IS_DISPLAYED_CALLS_SEARCH_DIALOG = 'SAVE_IS_DISPLAYED_CALLS_SEARCH_DIALOG';
export const SAVE_CALL_FILTERS = 'SAVE_CALL_FILTERS';
export const DELETE_FILTER = 'DELETE_FILTER';
export const DELETE_CALLS = 'DELETE_CALLS';
export const DELETE_CALLS_BY_UUID = 'DELETE_CALLS_BY_UUID';
export const DELETE_CALLS_BY_COLABORATOR_UUID = 'DELETE_CALLS_BY_COLABORATOR_UUID';
export const DELETE_CALLS_BY_CLIENT_ID = 'DELETE_CALLS_BY_CLIENT_ID';
export const DELETE_HISTORY_CALLS = 'DELETE_HISTORY_CALLS';
export const DELETE_HISTORY_CALL_MESSAGES = 'DELETE_HISTORY_CALL_MESSAGES';
export const ADD_MORE_CALL_PERIOD = 'ADD_MORE_CALL_PERIOD';
export const SAVE_IS_FETCHED_CALLS = 'SAVE_IS_FETCHED_CALLS';

export const saveCalls = (calls) => ({
    type: SAVE_CALLS,
    calls,
})

export const replaceCalls = (calls) => ({
    type: REPLACE_CALLS,
    calls,
})

export const saveCall = (call) => ({
    type: SAVE_CALL,
    call,
})

export const updateCall = (call) => ({
    type: UPDATE_CALL,
    call,
})

export const updateCallId = (oldId, newId) => ({
    type: UPDATE_CALL_ID,
    oldId,
    newId,
})

export const deleteCall = (call) => ({
    type: DELETE_CALL,
    call,
})

export const postCall = (call) => ({
    type: POST_CALL,
    call,
})

export const fetchCalls = () => ({
    type: FETCH_CALLS,
})

export const fetchMoreCalls = () => ({
    type: FETCH_MORE_CALLS,
})

export const searchCalls = (loadMore) => ({
    type: SEARCH_CALLS,
    loadMore,
})

export const saveIsFetchingCalls = (isFetchingCalls) => ({
    type: SAVE_IS_FETCHING_CALLS,
    isFetchingCalls,
})

export const saveIsFetchingMoreCalls = (isFetchingMoreCalls) => ({
    type: SAVE_IS_FETCHING_MORE_CALLS,
    isFetchingMoreCalls,
})

export const saveHistoryCalls = (historyCalls) => ({
    type: SAVE_HISTORY_CALLS,
    historyCalls,
})

export const saveHistoryCall = (historyCall) => ({
    type: SAVE_HISTORY_CALL,
    historyCall,
})

export const updateHistoryCall = (historyCall) => ({
    type: UPDATE_HISTORY_CALL,
    historyCall,
})

export const postHistoryCall = (historyCall) => ({
    type: POST_HISTORY_CALL,
    historyCall,
})

export const fetchHistoryCalls = (calls) => ({
    type: FETCH_HISTORY_CALLS,
    calls,
})

export const saveIsDisplayedHistoryCallModal = (callId, isDisplayedHistoryCallModal) => ({
    type: SAVE_IS_DISPLAYED_CALL_HISTORY_MODAL,
    callId,
    isDisplayedHistoryCallModal,
})

export const saveIsDisplayedCallClientDialog = (callId, isDisplayedCallClientDialog) => ({
    type: SAVE_IS_DISPLAYED_CALL_CLIENT_DIALOG,
    callId,
    isDisplayedCallClientDialog,
})

export const fetchCallsByCollaboratorUuid = (collaboratorUuid, historyCallStatus) => ({
    type: FETCH_CALLS_BY_COLLABORATOR_UUID,
    collaboratorUuid,
    historyCallStatus,
})

export const saveCallsByCollaboratorUuid = (callsByCollaboratorUuid, loginUuid) => ({
    type: SAVE_CALLS_BY_COLLABORATOR_UUID,
    callsByCollaboratorUuid,
    loginUuid,
})

export const saveSearchedCallsByCollaboratorUuid = (searchedCallsByCollaboratorUuid, loginUuid) => ({
    type: SAVE_SEARCHED_CALLS_BY_COLLABORATOR_UUID,
    searchedCallsByCollaboratorUuid,
    loginUuid,
})

export const updateCallByCollaboratorUuid = (callByCollaboratorUuid) => ({
    type: UPDATE_CALL_BY_COLLABORATOR_UUID,
    callByCollaboratorUuid,
})

export const saveCallsByCollaboratorUuidLastVisible = (callsByCollaboratorUuidLastVisble) => ({
    type: SAVE_CALLS_BY_COLLABORATOR_UUID_LAST_VISIBLE,
    callsByCollaboratorUuidLastVisble,
})

export const savesearchedCallsByUuidLastVisible = (searchedCallsByUuidLastVisible) => ({
    type: SAVE_SEARCH_CALLS_BY_UUID_LAST_VISIBLE,
    searchedCallsByUuidLastVisible,
})

export const replaceCallsByCollaboratorUuid = (callsByCollaboratorUuid) => ({
    type: REPLACE_CALLS_BY_COLLABORATOR_UUID,
    callsByCollaboratorUuid,
})

export const replaceSearchedCallsByCollaboratorUuid = (searchedCallsByCollaboratorUuid) => ({
    type: REPLACE_SEARCHED_CALLS_BY_COLLABORATOR_UUID,
    searchedCallsByCollaboratorUuid,
})

export const deleteCallByCollaboratorUuid = (callByCollaboratorUuid) => ({
    type: DELETE_CALL_BY_COLLABORATOR_UUID,
    callByCollaboratorUuid,
})

export const saveHistoryCallMessage = (historyCallMessage) => ({
    type: SAVE_HISTORY_CALL_MESSAGE,
    historyCallMessage,
})

export const saveBlankHistoryCallMessage = (historyCallId) => ({
    type: SAVE_BLANK_HISTORY_CALL_MESSAGE,
    historyCallId,
})

export const postHistoryCallMessage = (historyCallMessage) => ({
    type: POST_HISTORY_CALL_MESSAGE,
    historyCallMessage,
})

export const removeHistoryCallMessage = (historyCallMessage) => ({
    type: REMOVE_HISTORY_CALL_MESSAGE,
    historyCallMessage,
})

export const deleteHistoryCallMessage = (historyCallMessage) => ({
    type: DELETE_HISTORY_CALL_MESSAGE,
    historyCallMessage,
})

export const fetchCallsByClientId = (clientId) => ({
    type: FETCH_CALLS_BY_CLIENT_ID,
    clientId,
})

export const fetchMoreCallsByClientId = (clientId) => ({
    type: FETCH_MORE_CALLS_BY_CLIENT_ID,
    clientId,
})

export const saveCallsByClientId = (callsByClientId) => ({
    type: SAVE_CALLS_BY_CLIENT_ID,
    callsByClientId,
})

export const updateCallByClientId = (callByClientId) => ({
    type: UPDATE_CALL_BY_CLIENT_ID,
    callByClientId,
})

export const replaceCallsByClientId = (callsByClientId) => ({
    type: REPLACE_CALLS_BY_CLIENT_ID,
    callsByClientId,
})

export const saveCaller = (caller) => ({
    type: SAVE_CALLER,
    caller,
})

export const saveIsDisplayedCallsSearchDialog = (isDisplayedCallsSearchDialog) => ({
    type: SAVE_IS_DISPLAYED_CALLS_SEARCH_DIALOG,
    isDisplayedCallsSearchDialog,
})

export const saveCallFilters = (defaultFilters, filters, currentPageName) => ({
    type: SAVE_CALL_FILTERS,
    filters,
    currentPageName,
    defaultFilters,
})

export const deleteFilter = (currentPageName) => ({
    type: DELETE_FILTER,
    currentPageName,
})

export const deleteCalls = () => ({
    type: DELETE_CALLS,
})

export const deleteCallsByUuid = () => ({
    type: DELETE_CALLS_BY_UUID,
})

export const deleteCallsByCollaboratorUuid = () => ({
    type: DELETE_CALLS_BY_COLABORATOR_UUID,
})

export const deleteHistoryCalls = () => ({
    type: DELETE_HISTORY_CALLS,
})

export const deleteHistoryCallMessages = () => ({
    type: DELETE_HISTORY_CALL_MESSAGES,
})

export const deleteCallsByClientId = () => ({
    type: DELETE_CALLS_BY_CLIENT_ID,
})

export const addMoreCallPeriod = (currentPageName) => ({
    type: ADD_MORE_CALL_PERIOD,
    currentPageName,
})

export const saveIsFetchedCalls = (isFetchedCalls) => ({
    type: SAVE_IS_FETCHED_CALLS,
    isFetchedCalls,
})

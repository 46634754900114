import { SAVE_SNACKBAR, CLEAN_SNACKBAR } from "./actionSnackbar";
import { closeSnackbar } from "notistack";
const initialState = {
  snackbar: {
    // message: '...'
    // variant: 'primary' | 'secondary' | ect...
  },
}

const reducerSnackbar = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          message: action.message,
          variant: action.variant,
        },
      }

    case CLEAN_SNACKBAR: {
      closeSnackbar();
      return {
        ...state,
        snackbar: {},
      }
    }

    default: return state
  }
}

export default reducerSnackbar;
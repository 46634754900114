import { connect } from "react-redux";
import RequireAuth from "../components/RequireAuth";
// actions
import { signInWazoWithContext, checkIsValidWazoToken, connectToWazoApp, checkIsMostRecentVersion } from "../redux/login/actionLogin";

const mapStateToProps = (state, ownProps) => ({
    children: ownProps.children,
    isConnected: state.login.isConnected,
    isConnectingWithContext: state.login.isConnectingWithContext,
    token: state.login.token,
    isCheckingWazoToken: state.login.isCheckingWazoToken,
    isCheckingIsMostRecentVersion: state.login.isCheckingIsMostRecentVersion,
    isConnectingToWazoApp: state.login.isConnectingToWazoApp,
    isDisconnectWazoApp: state.login.isDisconnectWazoApp,
    currentTabIndex: state.tabs.currentTabIndex,
})

const mapDispatchToProps = (dispatch) => ({
    checkIsValidWazoToken: (wazoToken) => dispatch(checkIsValidWazoToken(wazoToken)),
    connectToWazoApp: () => dispatch(connectToWazoApp()),
    checkIsMostRecentVersion: (wazoToken) => dispatch(checkIsMostRecentVersion(wazoToken)),
    signInWazoWithContext: () => dispatch(signInWazoWithContext()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth)
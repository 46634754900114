// Firebase
import { Timestamp } from "firebase/firestore";

import {
  collection,
  setDoc,
  query,
  getDocs,
  doc,
  where,
  deleteDoc
} from "firebase/firestore";

export const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()]

/**  Quand l'utilisateur se connecte, le token a une durée de vie de 7 jours (604800 * 1000) secondes
il expirera 3 jours avant sa fin de vie (3 * 86400 * 1000 = 259200 * 1000 secondes)
*/
export const getIsExpired = (expiresAt, options) => {
  if (undefined === expiresAt) {
    return true;
  }
  if ('Invalid Date' === expiresAt.toString()) {
    return true;
  }

  const currentOptions = {
    timeBeforeExpiration: options?.timeBeforeExpiration ?? 259200,
    debug: options?.debug ?? false,
  }
  const isExpired = new Date().getTime() + (currentOptions.timeBeforeExpiration * 1000) > expiresAt.getTime()

  if (currentOptions.debug) {
    console.log({ ...currentOptions })
    console.log({expiresAt});
    console.log({'new Date()': new Date()})
    console.log({'result': isExpired})
  }

  return isExpired;
};

export const slugify = (str)  => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str.replace(/[^a-z0-9\u00C0-\u024F\u1E00-\u1EFF.&' -]/g, '') // remove any non-alphanumeric characters execpt character with accent and / & '
          //  .replace(/\s+/g, '-') // replace spaces with hyphens
           .replace(/-+/g, '-'); // remove consecutive hyphens
  return str;
}

export const timer = ms => new Promise(res => setTimeout(res, ms))

export const addWeeks = (date, weeks) => {
  date.setDate(date.getDate() + 7 * weeks);

  return date;
}

export const getTwoDigitTime = (time) => time.toString().padStart(2, '0');

// pour supprimer un call en bdd à partir de user.id
// eslint-disable-next-line no-unused-vars
export const deleteByUserId = async (userId, db) => {
  const callsCol = collection(db, 'calls');

  const q = query(
    callsCol,
    // filtre les appels en fonction de l'utilisateur connecté
    where("userId", "==", userId),
  )

  const callsSnapshot = await getDocs(q);

  const callsToDelete = callsSnapshot.docs.map(doc => doc.data());

  callsToDelete.forEach(
    async (call) => {
      await deleteDoc(doc(db, "calls", call.id));
    }
  )
}

// Change les dates qui ont un mauvais format pour avoir un TimeStamp
export const adjustDateFormatForCall = async (db) => {
  const callsCol = collection(db, 'calls');
  const q = query(callsCol);
  const callsSnapshot = await getDocs(q);

  callsSnapshot.docs.map(document => {
    let call = document.data();
    let callDate = call.date;
    if(isNaN(callDate)) {
      console.log(callDate)
      var timestampMilliseconds = (callDate.seconds * 1000) + Math.floor(callDate.nanoseconds / 1e6);
      const val = Timestamp.fromDate(new Date(timestampMilliseconds))
      call.date = val
      const callRef = doc(db, "calls", call.id);
      setDoc(callRef, call)
      console.log(call)
    }
    return document.data()
  } );
}


import React from 'react'
import PropTypes from 'prop-types'
// MUI
import { Box } from '@mui/material';
// icons
import EuroIcon from '@mui/icons-material/Euro';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const HistoryCallObjectIcon = ({ iconeName, sx })  => {
  const componentsMap = { 
    'Comptabilité': 
      {'icon': EuroIcon }
    ,
    'Personnel': 
      {'icon': AccountCircleOutlinedIcon }
    ,
    'Prise de RDV':
      { 'icon': CalendarMonthOutlinedIcon }
    ,
    'Renseignements':
      { 'icon': InfoOutlinedIcon }
    ,
    'Autres...':
      { 'icon': HelpOutlineOutlinedIcon }
    ,
    'empty':
      { 'icon': Box }
    ,
  }
  const Icon = componentsMap[iconeName]?.icon;

  return (
    <>
      { Icon && <Icon sx={{ ...sx, width: 'empty' !== iconeName ? 'inherit' : ['24px', '24px', 0] }} /> }
    </>
  )
}

HistoryCallObjectIcon.defaultProps = {
  iconeName: 'empty',
};

HistoryCallObjectIcon.propTypes = {
  // nom de l'icone
  iconeName: PropTypes.string.isRequired,
  // style de l'icone
  sx: PropTypes.object.isRequired,
}

export default HistoryCallObjectIcon
// React redux
import { connect } from "react-redux";

// Components
import Landing from "../components/Landing";

// Actions
import { saveTabIndex } from "../redux/tabs/actionTabs";
import { fetchUsers } from "../redux/users/actionUsers";
import {
  fetchClients,
} from "../redux/clients/actionClients";
import { logOut } from "../redux/login/actionLogin";
import {
  fetchCalls,
} from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  openCallsByCollaboratorUuid: state.calls.callsByCollaboratorUuid.filter((call) => state.calls.historyCalls.filter((historyCall) => historyCall.id === call.historyId)[0].status === 0),
  users: state.users.users,
  isAuthenticationLoading: state.login.isAuthenticationLoading,
  isConnected: state.login.isConnected,
  isConnectingWebsocket: state.websocketWazo.isConnectingWebsocket,
  calls: state.calls.calls,
  clients: state.clients.clients,
  isFetchingCalls: state.calls.isFetchingCalls,
  isFetchingUsers: state.users.isFetchingUsers,
  isFetchingClients: state.clients.isFetchingClients,
  isDisconnecting: state.login.isDisconnecting,
  userEmail: state.login.email,
})

const mapDispatchToProps = (dispatch) => ({
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchCalls: () => dispatch(fetchCalls()),
  fetchClients: (accessToken) => dispatch(fetchClients(accessToken)),
  logOut: () => dispatch(logOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
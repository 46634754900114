export const LOGOUT_CODE = 1000;

class Socket {
  constructor() {
    this.socket = null
    this.events = null
    this.delay = 30000
  }

  init(url, events) {
    if (!this.socket) {
      this.socket = new WebSocket(url);
      this.events = events;
    }
  }

  connect() {
    this.subscribe()
    this.start();
    this.heartbeat()
  }

  disconnect() {
    if (this.socket) {
      clearTimeout(this.pingTimeout)
      this.socket.close(LOGOUT_CODE, 'logout')
    }
  }

  start() {
    this.send({ op: 'start' });
 
  }

  subscribe() {
    const messages = this.events.map((eventName) => (
      {
        op: 'subscribe',
        data: {
          event_name: eventName,
        },
      }
    ))
    
    messages.forEach((message) => {
      this.send(message);
    })
  }


  send(message) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message))
    }
  }

  on(eventName, callback) {
    if (this.socket) {
      this.socket.addEventListener(eventName, callback)
    }
  }

  ping(delay) {
    clearTimeout(this.pingTimeout)
    
    this.pingTimeout = setInterval(() => {
      this.send({ op: "ping", data: { 'payload': 'heartbeat' } })
    }, delay)
}

  heartbeat() { 
    this.ping(this.delay) 
  }


}

export default Socket;
import React from 'react'
import PropTypes from 'prop-types'
// components
import HistoryCallObjectIcon from './HistoryCallObjectIcon';
// material
import { Typography } from '@mui/material';

const HistoryCallModalMenuItemIcon = ({ itemName }) => {
  return (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      <HistoryCallObjectIcon iconeName={itemName} sx={{ marginRight: 1, color: "secondary.main" }} />
        { itemName }
    </Typography>
  )
}

HistoryCallModalMenuItemIcon.propTypes = {
  itemName: PropTypes.string.isRequired,
}

export default HistoryCallModalMenuItemIcon
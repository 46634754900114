import {
  getDb,
  getGenapiLoginUrl,
  getGenapiApiUrl,
  getGenapiSecret,
  getGenapiTenantId,
} from '../../firebase/firebaseConfig';
import { slugify, addWeeks } from "../../utils";
// Firestore
import { doc, collection, getDocs, query, where, setDoc, Timestamp } from "firebase/firestore";
// Permet le hash en md5
import md5 from "md5-webworker";
// Actions
import {
  FETCH_CLIENTS,
  FETCH_FULL_CLIENT,
  saveGenapiToken,
  FETCH_FOLDERS,
  saveFolders,
  saveIsFetchingFolders,
  saveIsFetchingClients,
  saveFiles,
  FETCH_FILES,
  saveFile,
  fetchFiles,
  FETCH_FILE,
  saveIsFetchingFiles,
  UPLOAD_FILE,
  FETCH_BINDERS,
  saveBinders,
  saveIsUploadingFile,
  saveIsFetchingFullClient,
  replaceClients,
  saveFullClient,
  saveIsFetchingClientError,
} from "./actionClients";
// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

const clientsMiddleware = (store) => (next) => (action) => {
  let token;

  const loginWazoTenantId = store.getState().login.tenantUuid;
  const db = getDb(loginWazoTenantId);
  const tenantId = getGenapiTenantId(loginWazoTenantId);
  const urlLogin = getGenapiLoginUrl(loginWazoTenantId);
  const urlApi = getGenapiApiUrl(loginWazoTenantId);
  const secret = getGenapiSecret(loginWazoTenantId);

  const handleErrors = (tryName, error) => {
    const currMessage = error.message ?? 'inconnu';
    const currStatus = error.status ?? error.code ?? 'inconnu';

    store.dispatch(saveSnackbar(`Oups ! Une erreur inatendue s'est produite : message : ${currMessage} ~ status : ${currStatus}`, 'error'));
    store.dispatch(saveIsFetchingClientError(true));
    console.log(`Oups ! Une erreur inatendue s'est produite : ${tryName} ~ message : ${currMessage} ~ status : ${currStatus}`);
  }

  const isExpiredToken = () => {
    if (
      '' === store.getState().clients.accessToken 
      || undefined === store.getState().clients.accessToken
      || '' === store.getState().clients.expires_in 
      || undefined === store.getState().clients.expires_in
    ) {
      return true;
    }
    return (new Date()).getTime() > store.getState().clients.expires_in;
  }

  const getToken = async () => {
    try {
      // Configuration de la requete permettant de récupérer le token
      const bodyGetToken = {
        'grant_type': 'client_credentials',
        'scope': 'actes.client:read actes.partenaire:read actes.sousdossier:read actes.sousdossier.links:read actes.partenaire:read actes.immeuble:read actes.client.document:write actes.sousdossier.document:write actes.document:write actes.document:read actes.client.links:read actes.utilisateur:read actes.dossier:read'
      };

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          tenantId,
          'Authorization': `Basic ${secret}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(bodyGetToken)
      };
      const rawLogin = await fetch(`${urlLogin}/Authorization/OAuth/Token`, config)
      const { access_token, expires_in, refresh_token, token_type } = await rawLogin.json();

      const expiresInTimestamp = new Date(new Date().getTime() + (1000 * expires_in)).getTime();

      store.dispatch(saveGenapiToken(access_token, expiresInTimestamp, refresh_token, token_type));

      return access_token;

    } catch (error) {
      handleErrors('getToken', error);
    }
  }

  switch (action.type) {
    case FETCH_FULL_CLIENT:
      store.dispatch(saveIsFetchingClientError(false));
      store.dispatch(saveIsFetchingFullClient(true));

      /**
       * Rajoute le client dans firebase
       * 
       * @param {object} fullClient - 
       */
      const addFirebaseFullClient = async (fullClient) => {
        try {
          await setDoc(doc(db, "fullClients", fullClient.id.toString()), fullClient);
        } catch (error) {
          handleErrors('FETCH_FULL_CLIENT - addFirebaseFullClient', error);
        }
      }

      /**
       * Recupere le client depuis l'API Genapi
       * 
       * @returns {object|null}
       */
      const getGenapiFullClient = async () => {
        try {
          console.log('FULL_CLIENT VIA GENAPI')
          // recupere le token courant et verifie si il expiré
          token = store.getState().clients.accessToken;
          if (isExpiredToken()) {
            token = await getToken();
          }

          const clients = store.getState().clients.clients;

          const searchedClient = clients.filter((client) => client.id === parseInt(action.clientId))[0]

          const getConfig = (token) => ({
            method: 'get',
            maxBodyLength: Infinity,
            headers: {
              tenantId,
              'Authorization': 'Bearer ' + token,
            },
          });

          let config = getConfig(token);

          const params = {
            search: slugify(searchedClient?.denomination ?? ''),
          };

          if ('' === params.search) {
            return null;
          }

          let rawClient = await fetch(`${urlApi}/actes/api/v1/clients/search?${new URLSearchParams(params)}`, config);

          // si le token n'est pas valide
          if (rawClient?.status === 401 || rawClient?.status === 403) {
            token = await getToken();
            config = getConfig(token);
            rawClient = await fetch(`${urlApi}/actes/api/v1/clients/search?${new URLSearchParams(params)}`, config);
          }

          const foundedClients = await rawClient.json();
          const foundedClientsItems = foundedClients?.items;

          // si il n'y a pas "items" c'est que l'api retourne une erreur
          if (!foundedClientsItems) {
            const error = new Error();
            error.message = foundedClients.title;
            error.status = foundedClients.status;
            throw error;
          }

          if (0 === foundedClientsItems.length) {
            return null;
          }

          const genapiClient = foundedClientsItems.filter((client) => client.id === searchedClient.id)[0];
          const { liens, actions, ...client } = genapiClient;

          // une "dateRecuperation" est rajouté afin de savoir dans quel cas le client est récupéré via firebase ou genapi
          return { ...client, dateRecuperation: Timestamp.fromDate(new Date()) } ?? null;
        } catch (error) {
          handleErrors('FETCH_FULL_CLIENT - getGenapiFullClient', error);
        }
      }
      /**
       * Recupere le client depuis la bdd firebase
       * 
       * @returns {object|null}
       */
      const getFirebaseFullClient = async () => {
        try {
          console.log('FULL_CLIENT VIA FIREBASE')
          const fullClientsCol = collection(db, 'fullClients');

          const queryGetFirebaseClients = query(
            fullClientsCol,
            where('id', "==", parseInt(action.clientId)),
          )

          const firebaseFullClients = await getDocs(queryGetFirebaseClients);

          const fullClientsList = firebaseFullClients.docs.map(doc => doc.data());

          if (0 === fullClientsList.length) {
            return null;
          }

          return fullClientsList[0];
        } catch (error) {
          handleErrors('FETCH_FULL_CLIENT - getFirebaseFullClient', { message: error.toString(), status: error.code })
        }
      }

      /**
       * Recupère le client via Genapi si la date de recupération à plus de 7 jours
       * Sinon le recupère dans la bdd firebase
       */
      const getFullClient = async () => {
        try {
          const clients = store.getState().clients.fullClients;
          let fullClient = clients.filter(({ id }) => parseInt(action.clientId) === id)?.[0];
          if (fullClient) {
            console.log('FULL_CLIENT VIA STORE')
            return
          }

          // firebaseFullClient
          fullClient = await getFirebaseFullClient();

          const dateRecuperation = fullClient?.dateRecuperation ? new Date(fullClient?.dateRecuperation.seconds * 1000) : new Date();

          const isExpired = new Date().getTime() > addWeeks(dateRecuperation, 1).getTime();

          // si le client n'est pas présent en bdd tente de le recupérer sur genapi
          if (!fullClient || isExpired) {
            fullClient = await getGenapiFullClient();

            // si le client n'est tjs pas présent remonte une 404
            if (!fullClient) {
              const error = new Error();
              error.message = 'client non trouvé';
              error.status = 404;
              throw error;
            }

            // rajoute le client en bdd pour le prochain usage
            addFirebaseFullClient(fullClient);
          }

          store.dispatch(saveFullClient(fullClient));
        } catch (error) {
          handleErrors('FETCH_CLIENTS', error);
        } finally {
          store.dispatch(saveIsFetchingFullClient(false))
        }
      }

      getFullClient();

      next(action);
      break;

    case FETCH_CLIENTS:
      store.dispatch(saveIsFetchingClientError(false));
      store.dispatch(saveIsFetchingClients(true))

      const req = async () => {
        try {
          if (store.getState().clients.clients.length === 0) {
            console.log('CLIENTS VIA FIREBASE')
            // Récupération des clients présent dans firebase en fonction du tenantId
            const clientsCol = collection(db, 'clients');
            let queryGetFirebaseClients = query(
              clientsCol,
              where('tenantId', "==", tenantId),
            )
            const firebaseClients = await getDocs(queryGetFirebaseClients);

            const clientsList = firebaseClients.docs.map(doc => doc.data())

            const clients = clientsList.reduce(
              (acc, current) => {
                const currentClients = current.clients
                  .filter((client) => client.denomination !== '')
                  .map((client) => ({ ...client, denomination: client.denomination.trim() }))
                  ;

                return [...acc, ...currentClients];
              },
              [],
            )

            store.dispatch(replaceClients(clients));
          } else {
            console.log('CLIENTS VIA STORE')
          }
        } catch (error) {
          handleErrors('FETCH_CLIENTS - req', error);
        } finally {
          store.dispatch(saveIsFetchingClients(false))
        }
      }

      req();
      next(action);
      break;

    case FETCH_FOLDERS:
      // Activation du loader
      store.dispatch(saveIsFetchingClientError(false));
      store.dispatch(saveIsFetchingFolders(true))

      let folders = [];
      let totalPagesFolders = 0;


      const reqFolders = async () => {
        // Folders via store
        folders = store.getState().clients.folders;
        folders = folders.filter(({ clientId }) => parseInt(action.clientId) === clientId);
        if (folders.length !== 0) {
          console.log('FOLDERS VIA STORE');
        } else {
          console.log('FOLDERS VIA GENAPI');
          token = store.getState().clients.accessToken;
          if (isExpiredToken()) {
            token = await getToken();
          }

          // Configuration de la requete permettant de récupérer les dossiers
          let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: ``,
            headers: {
              tenantId,
              'Authorization': 'Bearer ' + token,
            },
          };

          let params = new URLSearchParams({
            start: 0,
            length: 50,
          })
          // Boucle permettant de consulter toute les pages de l'api
          do {
            try {
              const rawFolders = await fetch(`${urlApi}/actes/api/v1/clients/${action.clientId}/sousdossiers/?${params}`, config)
              // Extraction des données
              let { count, items, total } = await rawFolders.json();
              totalPagesFolders = total;
              folders = folders.concat(items);
              params.start += count
            } catch (error) {
              handleErrors('FETCH_FOLDERS - reqFolders', error);
            }
          } while (params.start < totalPagesFolders)
        }
        // Suppression des liens 
        const lightFolders = folders.map((folder) => ({
          clerc: folder.clerc,
          clercSecondaire: folder.clercSecondaire,
          dateCreation: folder.dateCreation,
          dateDernierAcces: folder.dateDernierAcces,
          dateModification: folder.dateModification,
          dateOuvertureDossier: folder.dateOuvertureDossier,
          datePrevisionnelleSignature: folder.datePrevisionnelleSignature,
          id: folder.id,
          idDossierParent: folder.idDossierParent,
          intitule: folder.intitule,
          montantPrevisionnel: folder.montantPrevisionnel,
          nature: folder.nature,
          nom: folder.nom,
          notaire: folder.notaire,
          numero: folder.numero,
          secretaire: folder.secretaire,
          service: folder.service,
          typeNature: folder.typeNature,
          valeurMetierEtat: folder.valeurMetierEtat,
          clientId: action.clientId,
        }))
        store.dispatch(saveFolders(lightFolders));
        store.dispatch(saveIsFetchingFolders(false))
      }

      reqFolders();
      next(action);
      break;

    case FETCH_FILES:
      // Activation du loader
      store.dispatch(saveIsFetchingFiles(true))

      const reqFiles = async () => {
        // recupere le token courant et verifie si il expiré
        token = store.getState().clients.accessToken;
        if (isExpiredToken()) {
          token = await getToken();
        }
        let files = [];
        let rawFiles = {};
        let totalPages = 0;

        let params = new URLSearchParams({
          start: 0,
          length: 50,
        });
        // Configuration de la requete permettant de récupérer les fichiers
        const getConfig = (token) => ({
          method: 'get',
          maxBodyLength: Infinity,
          headers: {
            tenantId,
            'Authorization': 'Bearer ' + token,
          },
        });

        let config = getConfig(token);

        do {
          try {
            rawFiles = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/documents/?${params}`, config)

            // si le token n'est pas valide
            if (rawFiles?.status === 401 || rawFiles?.status === 403) {
              token = await getToken();
              config = getConfig(token)
              
              rawFiles = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/documents/?${params}`, config)
            }

            // Extraction des données
            let { count, items, total } = await rawFiles.json();
            totalPages = total;
            files = files.concat(items);
            params.start += count
          } catch (error) {
            // Suppression du message d'erreur indiquant la limite du nombre de requête atteinte
            if (error?.response?.status !== 429) {
              console.log("FETCH_FILES ~ error:", error)
              handleErrors('FETCH_FILES', error);
            }
          }
        } while (params.start < totalPages)

        // Suppression des liens 
        const lightFiles = files.map((file) => ({
          dateCreation: file.dateCreation,
          dateModification: file.dateModification,
          id: file.id,
          idClasseurParent: file.idClasseurParent,
          intitule: file.intitule,
          type: file.type,
          uploadTermine: file.uploadTermine,
        }))

        store.dispatch(saveFiles(lightFiles));
        store.dispatch(saveIsFetchingFiles(false))
      }

      reqFiles();
      next(action);
      break;

    case FETCH_FILE:
      // Activation du loader
      store.dispatch(saveIsFetchingFiles(true))

      let downloadedFile = [];
      const reqFile = async () => {
        try {
          // recupere le token courant et verifie si il expiré
          token = store.getState().clients.accessToken;
          if (isExpiredToken()) {
            token = await getToken();
          }

          // Configuration de la requete permettant de récupérer le fichier
          const getConfig = (token) => ({
            method: 'get',
            maxBodyLength: Infinity,
            url: ``,
            headers: {
              tenantId,
              'Authorization': 'Bearer ' + token,
            },
          });

          let config = getConfig(token);
          let rawFile = await fetch(`${urlApi}/actes/api/v1/documents/${action.fileId}/data`, config);

          // si le token n'est pas valide
          if (rawFile?.status === 401 || rawFile?.status === 403) {
            token = await getToken();
            config = getConfig(token)
            
            rawFile = await fetch(`${urlApi}/actes/api/v1/documents/${action.fileId}/data`, config);
          }

          // Conversion du fichier en objet blob 
          downloadedFile = await rawFile.blob()

          // Gestion du téléchargement du fichier
          let objectUrl = window.URL.createObjectURL(downloadedFile);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download = action.fileIntitule;

          // Il faut append l'element au DOM sinon ca ne fonctionne pas avec firefox
          document.body.appendChild(a);
          a.click();
          // on l'enlève ensuite
          a.remove();

          // store.dispatch(saveFile(downloadedFile));
        } catch (error) {
          // Suppression du message d'erreur indiquant la limite du nombre de requête atteinte
          if (error?.response?.status !== 429) {
            console.log("FETCH_FILE ~ error:", error)
            handleErrors('FETCH_FILE', error);
          }

        }
        store.dispatch(saveFile({ downloadedFile }));
        store.dispatch(saveIsFetchingFiles(false))
      }

      reqFile();
      next(action);
      break;

    case UPLOAD_FILE:
      // Activation du loader
      store.dispatch(saveIsUploadingFile(true))
      const sendFile = async () => {
        // recupere le token courant et verifie si il expiré
        token = store.getState().clients.accessToken;
        if (isExpiredToken()) {
          token = await getToken();
        }

        // Hashage MD5 du fichier
        const hashMd5UploadingFile = await md5(action.uploadingFile);
        const hash = hashMd5UploadingFile.toUpperCase();

        // Extraction du nom du fichier
        let fileName = action.uploadingFile.name.split('.')
        fileName = fileName[0]

        const getConfig = (token) => {
          // Gestion du classeur ou ajouter le fichier
          let idClasseurParent = '';
          if (action.binderId) {
            idClasseurParent = action.binderId
          }

          return {
            method: 'post',
            body: JSON.stringify({
              "intitule": fileName,
              "nomFichier": action.uploadingFile.name,
              "hashFichierUpload": hash,
              "idClasseurParent": idClasseurParent,
            }),
            maxBodyLength: Infinity,
            url: ``,
            headers: {
              tenantId,
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
          }
        };
    
        // Mise en place de la config de la requête
        let config = getConfig(token);

        // Création du Edocument
        try {
          // Requête création du eDocument
          let rawFile = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/edocument`, config)

          // si le token n'est pas valide
          if (rawFile?.status === 401 || rawFile?.status === 403) {
            token = await getToken();
            config = getConfig(token)
            rawFile = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/edocument`, config)
          }

          let eDocument = await rawFile.json();

          try {
            const getConfig = (token) => {
              // Création du Headers
              let myHeaders = new Headers();
              myHeaders.append("tenantId", "70");
              myHeaders.append("Authorization", 'Bearer ' + token);

              // Création du formdata avec le fichier et le hash
              let formdata = new FormData();
              formdata.append("", action.uploadingFile);
              formdata.append("HashMd5", hash);

              return {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
              }
            }

            let config = getConfig(token);

            let response = await fetch(`${urlApi}/actes/api/v1/documents/${eDocument.id}/data`, config);

            // si le token n'est pas valide
            if (response?.status === 401) {
              token = await getToken();
              config = getConfig(token)
              response = await fetch(`${urlApi}/actes/api/v1/documents/${eDocument.id}/data`, config)
            }

            if (response.status !== 200) {
              console.log("UPLOAD_FILE ~ error:", response)
              handleErrors('UPLOAD_FILE', response.status);
            }
          } catch (error) {
            // Suppression du message d'erreur indiquant la limite du nombre de requête atteinte
            if (error?.response?.status !== 429) {
              console.log("UPLOAD_FILE ~ error:", error)
              handleErrors('UPLOAD_FILE', error);
            }
          }
        } catch (error) {
          // Suppression du message d'erreur indiquant la limite du nombre de requête atteinte
          if (error?.response?.status !== 429) {
            console.log("UPLOAD_FILE ~ error:", error)
            handleErrors('UPLOAD_FILE', error);
          }
        }
        store.dispatch(saveIsUploadingFile(false))
        store.dispatch(fetchFiles(action.folderId))
        store.dispatch(saveSnackbar('le fichier: ' + fileName + ' a été ajouté !', 'info'));
      }
      sendFile();
      next(action);
      break;

    case FETCH_BINDERS:
      // Activation du loader
      store.dispatch(saveIsFetchingFiles(true))

      const reqBinders = async () => {
        token = store.getState().clients.accessToken;
        if (isExpiredToken()) {
          token = await getToken();
        }

        // Configuration de la requete permettant de récupérer les classeurs
        const getConfig = (token) => ({
          method: 'get',
          maxBodyLength: Infinity,
          headers: {
            tenantId,
            'Authorization': 'Bearer ' + token,
          },
        });
        let config = getConfig(token)
        
        let binders = [];
        let rawBinders = {};
        let totalPages = 0;

        let params = new URLSearchParams({
          start: 0,
          length: 50,
        });

        do {
          try {
            rawBinders = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/classeurs/?${params}`, config);

            // si le token n'est pas valide
            if (rawBinders?.status === 401 || rawBinders?.status === 403) {
              token = await getToken();
              config = getConfig(token)
              rawBinders = await fetch(`${urlApi}/actes/api/v1/sousdossiers/${action.folderId}/classeurs/?${params}`, config);
            }
            // Extraction des données
            let { count, items, total } = await rawBinders.json();
            totalPages = total;
            binders = binders.concat(items);
            params.start += count
          } catch (error) {
            // Suppression du message d'erreur indiquant la limite du nombre de requête atteinte
            if (error?.response?.status !== 429 && rawBinders?.status !== 429) {
              console.log("FETCH_CLASSEURS ~ error:", error)
              handleErrors('FETCH_CLASSEURS', error);
            }
          }

        } while (params.start < totalPages)
        // Suppression des liens 
        const lightBinders = binders.map((binder) => ({
          id: binder.id,
          idClasseurParent: binder.idClasseurParent,
          idSousDossierParent: binder.idSousDossierParent,
          nom: binder.nom,
          ordre: binder.ordre,
        }))

        // Ajout du classeur virtuel à l'id 9999 présent sur INOT
        var AddClasseurVirtuel = lightBinders;
        AddClasseurVirtuel.unshift({ id: 9999, idClasseurParent: 0, idSousDossierParent: action.folderId, nom: 'Classeur virtuel', ordre: 9999 });

        store.dispatch(saveBinders(AddClasseurVirtuel));
        store.dispatch(saveIsFetchingFiles(false));
      }
      reqBinders();
      next(action);
      break;

    default: next(action)
  }
}
export default clientsMiddleware;
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "../redux/store";

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
// components
// containers
import LandingContainer from '../containers/LandingContainer';
import RequireAuthContainer from '../containers/RequireAuthContainer';
import LoginContainer from '../containers/LoginContainer';

// material
import { ThemeProvider, createTheme, styled } from '@mui/material';

//consts
import {
  PAGE_DEFAULT,
  PAGE_CALLS,
  PAGE_CLIENTS,
  PAGE_LOGIN,
  PAGE_CALL_HISTORY,
  PAGE_CLIENT,
} from '../pages';

// date picker
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

let theme = createTheme({
  palette: {
    primary: {
      main: '#2D4460',
    },
    secondary: {
      main: '#978570',
    },
    bg: {
      dark: '#a0a8aa',
      main: '#e5f0f4',
      light: '#FFF',
    },
    white: {
      main: '#FFF',
    }
  },
  spacing: (factor) => `${0.25 * factor}rem`,
});

theme.typography.h1 = {
  fontSize: '1.3rem',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.6rem',
  },
};

theme.typography.h2 = {
  fontSize: '1.1rem',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.h3 = {
  fontSize: '0.9rem',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.1rem',
  },
};

theme.typography.h4 = {
  fontSize: '0.8rem',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
};

theme.typography.body1 = {
  fontSize: '0.9rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
};

theme.typography.body2 = {
  fontSize: '0.73rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
};

// notistack :
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    flexWrap: 'nowrap',
  },
}));

const App = () => (
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5 * 1000}
            preventDuplicate={true}
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              info: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Routes>
                <Route exact path={PAGE_LOGIN} element={<LoginContainer />} />
                <Route exact path={PAGE_CLIENTS} element={<RequireAuthContainer>
                  <LandingContainer />
                </RequireAuthContainer>} />
                <Route exact path={PAGE_CLIENT} element={<RequireAuthContainer>
                  <LandingContainer />
                </RequireAuthContainer>} />
                <Route exact path={PAGE_CALLS} element={<RequireAuthContainer>
                  <LandingContainer />
                </RequireAuthContainer>} />
                <Route exact path={PAGE_CALL_HISTORY} element={<RequireAuthContainer>
                  <LandingContainer />
                </RequireAuthContainer>} />
                <Route path="*" element={<Navigate to={PAGE_DEFAULT} replace />} />
              </Routes>
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Router>
);

export default App;
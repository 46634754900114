import { getDb } from '../../firebase/firebaseConfig';
// firebase
import {
  collection,
  and,
  addDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  doc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
// actions
import {
  FETCH_CALLS,
  FETCH_MORE_CALLS,
  saveSearchedCallsByCollaboratorUuid,
  SEARCH_CALLS,
  POST_CALL,
  saveCalls,
  replaceCalls,
  saveIsFetchingCalls,
  POST_HISTORY_CALL,
  saveHistoryCalls,
  FETCH_CALLS_BY_COLLABORATOR_UUID,
  POST_HISTORY_CALL_MESSAGE,
  REMOVE_HISTORY_CALL_MESSAGE,
  saveCallsByCollaboratorUuidLastVisible,
  FETCH_CALLS_BY_CLIENT_ID,
  FETCH_MORE_CALLS_BY_CLIENT_ID,
  saveIsFetchingMoreCalls,
  saveCallsByClientId,
  replaceCallsByClientId,
  replaceSearchedCallsByCollaboratorUuid,
  savesearchedCallsByUuidLastVisible,
  saveIsFetchedCalls,
  updateCall,
} from "./actionCalls";

// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

import {
  connectWebsocketFirebaseHistoryCalls,
  connectWebsocketFirebaseCallsByHistoryCalls,
} from "../websocketFirebase/actionWebsocketFirebase";
// consts
import { MORE_CALL } from "./reducerCalls";
import { PAGE_CALL_HISTORY } from "../../pages";

const middlewareCalls = (store) => (next) => (action) => {
  const handleErrors = (tryName, error) => {
    const currMessage = error.message ?? 'inconnu';
    const currStatus = error.status ?? error.code ?? 'inconnu';

    console.log(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${currMessage} ~ ${currStatus}`, 'error');
    store.dispatch(saveSnackbar(`Oups ! Une erreur inattendue s'est produite : ${currMessage} ~ ${currStatus}`, 'error'));
  }

  switch (action.type) {
    case POST_CALL:
      console.log(POST_CALL, action.call.id);
      
      (async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          await setDoc(doc(db, "calls", action.call.id), action.call);
          store.dispatch(updateCall(action.call))
          // l'appel est ensuite traite par websocketFirevase.CONNECT_WEBSOCKET_FIREBASE_CALLS
        } catch (error) {
          handleErrors('POST_CALL', error);
        }
      })()

      next(action)
      break

    case FETCH_CALLS:
      const getCalls = async () => {
        try {
          let callsList = store.getState().calls.calls;
          const endedCallsList = callsList.filter((call) => 'call_ended' === call.status);

          const callsExpireAt = localStorage.getItem('callsExpireAt');
          let isExpired = !callsExpireAt ? true : new Date().getTime() >= new Date(callsExpireAt).getTime();
          
          // le but étant de charger les appels via firebase uniquement si cela n'a pas déjà été fait
          // uniquement les appels terminés seront chargés de cette façon c'est donc uniquement eux qui doivent etre vérifiés    
          if (0 === endedCallsList.length && !store.getState().calls.isFetchedCalls && isExpired) {
            console.log('CALLS VIA FIREBASE')
            const db =  getDb(store.getState().login.tenantUuid);
            const callsCol = collection(db, 'calls');
            const q = query(
              callsCol,
              // filtre les appels en fonction de l'utilisateur connecté
              where("userId", "==", store.getState().login.uuid),
              orderBy("date", "desc"),
              limit(MORE_CALL),
            )

            const callsSnapshot = await getDocs(q);
            callsList = callsSnapshot.docs.map(doc => doc.data());
            const tomorrow = new Date(new Date().getTime() + 3600 * 24 * 1000);
            localStorage.setItem('callsExpireAt', tomorrow)
            store.dispatch(saveIsFetchedCalls(true));
            store.dispatch(replaceCalls(callsList));
          } else {
            console.log('CALLS VIA STORE')
          }

          const historyCalls = callsList.map(({ historyId }) => ({ id: historyId }))
          // récupère la liste des historique d'appel
          if (callsList.length > 0) {
            store.dispatch(connectWebsocketFirebaseHistoryCalls(historyCalls))
          }

        } catch (error) {
          console.log("FETCH_CALLS ~ error:", error.code, '---', error.name, '---', error)

          handleErrors('FETCH_CALLS', error);
        } finally {
          store.dispatch(saveIsFetchingCalls(false))
        }
      }

      store.dispatch(saveIsFetchingCalls(true))
      getCalls();

      next(action)
      break

    case FETCH_MORE_CALLS:
      const getMoreCalls = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          const callsCol = collection(db, 'calls');
          // on souhaite récupérer à chaque requete x (ou x = MORE_CALL) appels supplémentaires
          const calls = store.getState().calls.calls;
          const callsLength = calls.length;          
          const lastCall = calls?.[callsLength - 1] ?? { date: new Date() };

          // sinon récupérera les x (ou x = MORE_CALL) suivants a partir du dernier appel de calls
          const q = query(
            callsCol,
            where("userId", "==", store.getState().login.uuid),
            orderBy("date", "desc"),
            startAfter(lastCall.date), // firebase Timestamp to Date
            limit(MORE_CALL),
          )

          const callsSnapshot = await getDocs(q);
          const callsList = callsSnapshot.docs.map(doc => doc.data());

          store.dispatch(saveCalls(callsList));

          const historyCalls = callsList.map(({ historyId }) => ({ id: historyId }))
          // récupère la liste des historique d'appel
          if (callsList.length > 0) {
            store.dispatch(connectWebsocketFirebaseHistoryCalls(historyCalls))
          }

        } catch (error) {
          handleErrors('FETCH_MORE_CALLS', error);
        } finally {
          store.dispatch(saveIsFetchingMoreCalls(false))
        }
      }

      store.dispatch(saveIsFetchingMoreCalls(true))
      getMoreCalls();

      next(action)
      break

    case SEARCH_CALLS:
      const currentFilters = { ...store.getState().calls.filters };
      const filters = { ...currentFilters[window.location.pathname] }

      const getSearchedCalls = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          const callsCol = collection(db, 'calls');

          let q;

          // Gestion Date et moreCall 
          // -30 pour le décalage d'1 mois pour la start Date et la endDate 
          const today = new Date();

          let newStartDateFilter = new Date();
          let newEndDateFilter = new Date();

          newEndDateFilter.setDate(today.getDate() - (30 * (filters.moreCalls - 1)))
          // Fin
          if (!filters.endDateFilter) {
            newEndDateFilter = newEndDateFilter.setHours(23, 59, 59, 0);
            newEndDateFilter = new Date(newEndDateFilter);
          } else {
            let formatedEndDateFilter = new Date(filters.endDateFilter)
            formatedEndDateFilter.setHours(23, 59, 59, 0);
            newEndDateFilter = formatedEndDateFilter;
          }

          // Début
          if (filters.startDateFilter) {
            newStartDateFilter = new Date(filters.startDateFilter);
            if (!filters.endDateFilter) {
              newEndDateFilter = new Date();
            }
          } else {
            newStartDateFilter.setDate(today.getDate() - (30 * filters.moreCalls) + 1)
          }

          let formatedFilters = []

          // Calls by clientId
          if (filters.clientId) {
            formatedFilters.push(where("clientId", "==", parseInt(filters.clientId)));
          }

          // Calls by collaborateurUUID
          if (filters.collaboratorUuid) {
            try {
              // loader
              store.dispatch(saveIsFetchingCalls(true));
              const db =  getDb(store.getState().login.tenantUuid);
              const historyCallsCol = collection(db, 'historyCalls');
              let q;

              let formatedHistoryCallsFilters = []

              // Gestion Date et moreCall 
              // -30 pour le décalage d'1 mois pour la start Date et la endDate 

              newStartDateFilter = new Date();
              newEndDateFilter = new Date();

              newEndDateFilter.setDate(today.getDate() - (30 * (filters.moreCalls - 1)))
              // Fin
              if (!filters.endDateFilter) {
                newEndDateFilter = newEndDateFilter.setHours(23, 59, 59, 0);
                newEndDateFilter = new Date(newEndDateFilter);
              } else {
                let formatedEndDateFilter = new Date(filters.endDateFilter)
                formatedEndDateFilter.setHours(23, 59, 59, 0);
                newEndDateFilter = formatedEndDateFilter;
              }
    
              // Début
              if (filters.startDateFilter) {
                newStartDateFilter = new Date(filters.startDateFilter);
                if (!filters.endDateFilter) {
                  newEndDateFilter = new Date();
                }
              } else {
                newStartDateFilter.setDate(today.getDate() - (30 * filters.moreCalls) + 1)
              }

              formatedHistoryCallsFilters.push(where("updatedAt", ">=", newStartDateFilter))
              formatedHistoryCallsFilters.push(where("updatedAt", "<=", newEndDateFilter))

              formatedHistoryCallsFilters.push(where("collaboratorUuid", "==", filters.collaboratorUuid))
              formatedHistoryCallsFilters.push(where("status", '==', store.getState().tabs.tabIndex[PAGE_CALL_HISTORY]))

              const wheresHistoryCalls = and(...formatedHistoryCallsFilters)

              // si c'est la 1ere requete
              if (store.getState().calls.searchedCallsByUuidLastVisible) {
                q = query(
                  historyCallsCol,
                  wheresHistoryCalls,
                  orderBy("updatedAt", "desc"),
                  // limit(MORE_CALL),
                  startAfter(store.getState().calls.searchedCallsByUuidLastVisible),
                );
                // sinon la recupération des données commencera a partir de lastVisible
              } else {
                q = query(
                  historyCallsCol,
                  wheresHistoryCalls,
                  orderBy("updatedAt", "desc"),
                  // limit(MORE_CALL),
                )
              }

              const documentSnapshots = await getDocs(q);
              const historyCallsList = documentSnapshots.docs.map(doc => doc.data());

              const historyCallsIds = historyCallsList.map(({ id }) => id)

              if (0 !== historyCallsList.length) {
                formatedFilters.push(where("historyId", 'in', historyCallsIds));
              } else {
                formatedFilters.push(where("historyId", 'in', ['0000000000.0000']));
              }

              // dernier call recupérer depuis la bd
              const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
              // le call est sauvegarder pour la pagination sache par quel element elle doit commencer
              store.dispatch(savesearchedCallsByUuidLastVisible(lastVisible ?? null))

              if (0 !== historyCallsList.length) {
                store.dispatch(connectWebsocketFirebaseCallsByHistoryCalls(historyCallsList))
                store.dispatch(saveHistoryCalls(historyCallsList))
              }

            } catch (error) {
              handleErrors('FETCH_CALLS_BY_COLLABORATOR_UUID', error);
            } finally {
              store.dispatch(saveIsFetchingCalls(false))
            }

          }

          // Denomination filter
          if (filters.clientFilter && !filters.clientId && !filters.collaboratorFilter) {
            formatedFilters.push(where("clientId", "==", filters.clientFilter))
          }

          // Collaborateur filter
          if (filters.collaboratorFilter && !filters.clientId && !filters.clientFilter) {
            formatedFilters.push(where("callNumber", "in", filters.collaboratorFilter))
          }

          // Date filter
          // start date 
          if (!filters.collaboratorUuid) {
            formatedFilters.push(where("date", ">=", newStartDateFilter))
          }
          // end date
          if (!filters.collaboratorUuid) {
            formatedFilters.push(where("date", "<=", newEndDateFilter))
          }

          formatedFilters.push(where("userId", "==", store.getState().login.uuid))


          const wheres = and(...formatedFilters)

          q = query(
            callsCol,
            wheres,
            orderBy("date", "desc"),
          )

          const callsSnapshot = await getDocs(q);

          let callsList = callsSnapshot.docs.map(doc => doc.data());

          // CallNumber filter
          if (filters.phoneFilter) {
            callsList = callsList.filter(call => {
              if (call.callNumber.includes(filters.phoneFilter)) {
                return true;
              }
              return false;
            })
          }

          // Start duration filter
          if (filters.startDurationFilter) {
            callsList = callsList.filter(call => {
              let startDuration = new Date(filters.startDurationFilter);
              let startDurationFilter = (startDuration.getHours() * 3600) + (startDuration.getMinutes() * 60) + (startDuration.getSeconds());
              if (startDurationFilter <= call.duration) {
                return true;
              }
              return false;
            })
          }

          // End duration filter
          if (filters.endDurationFilter) {
            callsList = callsList.filter(call => {
              let endDuration = new Date(filters.endDurationFilter);
              let endDurationFilter = (endDuration.getHours() * 3600) + (endDuration.getMinutes() * 60) + (endDuration.getSeconds());
              if (endDurationFilter >= call.duration) {
                return true;
              }
              return false;
            })
          }

          if (filters.collaboratorUuid) {
            // Sort by date
            // callsList.sort(function (a, b) {
            //   return b.date.seconds - a.date.seconds;
            // });
            if (action.loadMore) {
              store.dispatch(saveSearchedCallsByCollaboratorUuid(callsList, store.getState().login.uuid));
            } else {
              store.dispatch(replaceSearchedCallsByCollaboratorUuid(callsList));
            }
          } else if (filters.clientId) {
            if (action.loadMore) {
              store.dispatch(saveCallsByClientId(callsList));
            } else {
              store.dispatch(replaceCallsByClientId(callsList));
            }
          }
          else {
            if (action.loadMore) {
              store.dispatch(saveCalls(callsList));
            } else {
              store.dispatch(replaceCalls(callsList));
            }

          }

          let historyCalls = callsList.map(({ historyId }) => ({ id: historyId }))
          // récupère la liste des historique d'appel
          if (callsList.length > 0) {
            do {
              let historyCallsToAdd = historyCalls.slice(0, 30);
              historyCalls = historyCalls.slice(30);
              store.dispatch(connectWebsocketFirebaseHistoryCalls(historyCallsToAdd))
            } while (historyCalls.length > 0);
          }
        } catch (error) {
          handleErrors('FETCH_CALLS', error);
        } finally {
          store.dispatch(saveIsFetchingCalls(false))
        }
      }

      store.dispatch(saveIsFetchingCalls(true))
      getSearchedCalls();

      next(action)
      break


    // est appelé au moment de la mise à jour ou la fermeture d'un historique
    // est appelé au moment ou un appel se temrine dans le cas ou l'utilisateur a saisie l'historique
    case POST_HISTORY_CALL:
      const uploadHistoryCall = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          await setDoc(doc(db, "historyCalls", action.historyCall.id), action.historyCall);

          // permet d'eviter de faire doublon avec websocketFirebase.CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID
          // qui est déjà en charge de récupérer les historique donc l'utilisateur connecté est le collaborateur
          if (action.historyCall.collaboratorUuid !== store.getState().login.uuid) {
            store.dispatch(connectWebsocketFirebaseHistoryCalls([action.historyCall]))
          }
        } catch (error) {
          handleErrors('POST_HISTORY_CALL', error);
        }
      }

      uploadHistoryCall();

      next(action)
      break

    case FETCH_CALLS_BY_COLLABORATOR_UUID:
      // recupère la liste des historique des l'utilisateur connectés
      const getHistoryCallsByCollaboratorUiid = async () => {
        try {
          // loader
          // TODO : remetre le loader au chargement des appels
          // store.dispatch(saveIsFetchingCalls(true))
          const db =  getDb(store.getState().login.tenantUuid);
          const historyCallsCol = collection(db, 'historyCalls');

          let q;
          // si c'est la 1ere requete
          if (store.getState().calls.callsByCollaboratorUuidLastVisble) {
            q = query(
              historyCallsCol,
              where("collaboratorUuid", "==", action.collaboratorUuid),
              where("status", '==', action.historyCallStatus),
              orderBy("updatedAt", "desc"),
              limit(MORE_CALL),
              startAfter(store.getState().calls.callsByCollaboratorUuidLastVisble),
            );
            // sinon la recupération des données commencera a partir de lastVisible
          } else {
            q = query(
              historyCallsCol,
              where("collaboratorUuid", "==", action.collaboratorUuid),
              where("status", '==', action.historyCallStatus),
              orderBy("updatedAt", "desc"),
              limit(MORE_CALL),
            )
          }
          const documentSnapshots = await getDocs(q);
          const historyCallsList = documentSnapshots.docs.map(doc => doc.data());

          // dernier call recupérer depuis la bd
          const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
          // le call est sauvegarder pour la pagination sache depuis qu'elle element elle doit commencer
          store.dispatch(saveCallsByCollaboratorUuidLastVisible(lastVisible ?? null))

          if (0 !== historyCallsList.length) {
            store.dispatch(connectWebsocketFirebaseCallsByHistoryCalls(historyCallsList))
            store.dispatch(saveHistoryCalls(historyCallsList))
          }
        } catch (error) {
          handleErrors('FETCH_CALLS_BY_COLLABORATOR_UUID', error);
        } finally {
          // store.dispatch(saveIsFetchingCalls(false))
        }
      }

      getHistoryCallsByCollaboratorUiid()

      next(action)
      break

    case POST_HISTORY_CALL_MESSAGE:
      const uploadHistoryCallsMessage = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          await addDoc(collection(db, "historyCallMessages"), action.historyCallMessage);
        } catch (error) {
          handleErrors('POST_HISTORY_CALL_MESSAGE', error);
        }
      }

      uploadHistoryCallsMessage();

      next(action)
      break

    case REMOVE_HISTORY_CALL_MESSAGE:
      const removeHistoryCallsMessage = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          await deleteDoc(doc(db, "historyCallMessages", action.historyCallMessage.id));
        } catch (error) {
          handleErrors('REMOVE_HISTORY_CALL_MESSAGE', error);
        }
      }

      removeHistoryCallsMessage();

      next(action)
      break

    case FETCH_CALLS_BY_CLIENT_ID:
      // Récupère la liste des appels à partir d'un client séléctionné 
      const getCallsByClientId = async () => {
        try {
          let clientIdCallsList = store.getState().calls.callsByClientId.filter(({ clientId }) => clientId === parseInt(action.clientId));
          
          const callsExpireAt = localStorage.getItem('clientCallsExpireAt');
          const isExpired = new Date().getTime() >= new Date(callsExpireAt).getTime();
          
          // TODO : implanter le calls via store
          // if (0 === clientIdCallsList.length || isExpired) {
            console.log('CLIENT CALLS VIA FIREBASE')
            const db =  getDb(store.getState().login.tenantUuid);
            const clientCallsCol = collection(db, 'calls');
            const q = query(
              clientCallsCol,
              where("userId", "==", store.getState().login.uuid),
              where("clientId", "==", parseInt(action.clientId)),
              orderBy("date", "desc"),
              limit(MORE_CALL),
            )

            const clientCallsSnapshot = await getDocs(q);
            clientIdCallsList = clientCallsSnapshot.docs.map(doc => doc.data());

            const tomorrow = new Date(new Date().getTime() + 3600 * 24 * 1000);
            localStorage.setItem('clientCallsExpireAt', tomorrow)

            store.dispatch(replaceCallsByClientId(clientIdCallsList));
          // } else {
          //   console.log('CLIENT CALLS VIA STORE')
          // }

          // récupère la liste des historique d'appel et s'abonne au events pour les maj d'historique
          const historyCalls = clientIdCallsList.map(({ historyId }) => ({ id: historyId }))
          if (clientIdCallsList.length > 0) {
            store.dispatch(connectWebsocketFirebaseHistoryCalls(historyCalls))
          }
        } catch (error) {
          handleErrors('FETCH_CALLS_BY_CLIENT_ID', error);
        }
      }
      getCallsByClientId();

      next(action);
      break;
    
    case FETCH_MORE_CALLS_BY_CLIENT_ID:
      const getMoreCallsByClientId = async () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          const clientCallsCol = collection(db, 'calls');
          // on souhaite récupérer à chaque requete x (ou x = MORE_CALL) appels supplémentaires
          const calls = store.getState().calls.callsByClientId;
          const callsLength = calls.length;

          const lastCall = calls?.[callsLength - 1] ?? { date: new Date() };

          // sinon récupérera les x (ou x = MORE_CALL) suivants a partir du dernier appel de calls
          const q = query(
            clientCallsCol,
            where("userId", "==", store.getState().login.uuid),
            where("clientId", "==", parseInt(action.clientId)),
            orderBy("date", "desc"),
            startAfter(new Date(lastCall.date.seconds * 1000)),
            limit(MORE_CALL),
          )

          const callsSnapshot = await getDocs(q);
          const callsList = callsSnapshot.docs.map(doc => doc.data());

          store.dispatch(saveCallsByClientId(callsList));

          const historyCalls = callsList.map(({ historyId }) => ({ id: historyId }))
          // récupère la liste des historique d'appel
          if (callsList.length > 0) {
            store.dispatch(connectWebsocketFirebaseHistoryCalls(historyCalls))
          }

        } catch (error) {
          handleErrors('FETCH_MORE_CALLS_BY_CLIENT_ID', error);
        } finally {
          store.dispatch(saveIsFetchingMoreCalls(false))
        }
      }

      store.dispatch(saveIsFetchingMoreCalls(true))
      getMoreCallsByClientId();

      next(action)
      break

    default: next(action)
  }
}

export default middlewareCalls;
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import CallContainer from '../containers/CallContainer'
import CallsSearchDialogContainer from '../containers/CallsSearchDialogContainer';
import Loader from './Loader';

// MUI
import { Box, Typography } from '@mui/material';

// Const
import MoreCallsButton from './MoreCallsButton';

const CallsEnded = ({
  calls,
  fetchCalls,
  fetchMoreCalls,
  searchCalls,
  deleteCalls,
  isFetchingCalls,
  addMoreCallPeriod,
  filters,
}) => {
  const formatDateToFr = (date) => date.toLocaleString('fr-Fr').split(' ')[0]

  const formatCalls = useCallback(() => {
    calls.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return b.date.seconds - a.date.seconds;
    });

    return calls.reduce(
      (acc, curr) => {
        if (curr?.status === "call_ended") {
          const date = formatDateToFr(new Date(curr.date.seconds * 1000))
          const newData = acc[date] !== undefined
            ? [...acc[date], curr]
            : [curr]

          return {
            ...acc,
            [date]: newData,
          }
        }
        return acc;
      },
      {},
    )
  }, [calls])
  return (
    <Box sx={{ textAlign: 'center' }}>
      <>
        {isFetchingCalls
          ? (
            <Loader top="15%" size={200} color="secondary" />
          )
          :
          (
            <>
              { true && (
                <CallsSearchDialogContainer
                  searchCalls={searchCalls}
                  fetchCalls={fetchCalls}
                  defaultFilters={{}}
                  deleteCalls={deleteCalls}
                />
              )}
              {formatCalls() &&
                Object.keys(formatCalls()).map((dayCalls) => (
                  <Box key={dayCalls}>
                    {/* Date des appels */}
                    <Typography color="secondary" sx={{ fontWeight: 'bold' }}>{dayCalls === formatDateToFr(new Date()) ? "Aujourd'hui" : dayCalls}</Typography>
                    {/* Liste des appels par jour */}
                    <Box>
                      {
                        formatCalls()[dayCalls].map(
                          (call) => <CallContainer key={call.id} {...call} />
                        )
                      }
                    </Box>
                  </Box>
                )
                )
              }
              {/* more button */}
              <MoreCallsButton
                isFetchingCalls={isFetchingCalls}
                filters={filters}
                addMoreCallPeriod={addMoreCallPeriod}
                searchCalls={searchCalls}
                fetchCalls={fetchMoreCalls}
              />
            </>)
        }
      </>
    </Box>
  )
}

CallsEnded.propTypes = {
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // client Genapi cf Clients
      clientId: PropTypes.number.isRequired
    }).isRequired,
  ).isRequired,
  // telecharge les appels depuis firestore
  fetchCalls: PropTypes.func.isRequired,
  // telecharge x (ou x = MORE_CALL) appels supplémentaires depuis firestore
  fetchMoreCalls: PropTypes.func.isRequired,
  // Recherche d'appel
  searchCalls: PropTypes.func.isRequired,
  // Suppression des appels
  deleteCalls: PropTypes.func.isRequired,
  // si le telechargement des appels depuis firestore est en cours
  isFetchingCalls: PropTypes.bool.isRequired,
  // Add more call 
  addMoreCallPeriod: PropTypes.func.isRequired,
  // Filters
  filters: PropTypes.object.isRequired,
}

export default CallsEnded
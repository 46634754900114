export const SAVE_IS_DISPLAYED = 'SAVE_IS_DISPLAYED';
export const SAVE_IS_DOWNLOADING = 'SAVE_IS_DOWNLOADING';
export const SAVE_DOWNLOAD_PROGRESS = 'SAVE_DOWNLOAD_PROGRESS';
export const SAVE_IS_DOWNLOADED = 'SAVE_IS_DOWNLOADED';

export const saveIsDisplayed = (isDisplayed) => ({
    type: SAVE_IS_DISPLAYED,
    isDisplayed,
})

export const saveIsDownloading = (isDownloading) => ({
    type: SAVE_IS_DOWNLOADING,
    isDownloading,
})

export const saveDownloadProgress = (downloadProgress) => ({
    type: SAVE_DOWNLOAD_PROGRESS,
    downloadProgress,
})

export const saveIsDownloaded = (isDownloaded) => ({
    type: SAVE_IS_DOWNLOADED,
    isDownloaded,
})

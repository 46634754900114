import { getDb } from '../../firebase/firebaseConfig';
// Firebase
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
// actions
import {
  CONNECT_WEBSOCKET_FIREBASE_CALLS,
  CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS,
  connectWebsocketFirebaseCallsByHistoryCalls,
  CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS,
  connectWebsocketFirebaseHistoryCalls,
  CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES,
  connectWebsocketFirebaseHistoryCallMessages,
  CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID,
} from "./actionWebsocketFirebase";
import {
  saveHistoryCall,
  saveHistoryCalls,
  updateHistoryCall,
  deleteCallByCollaboratorUuid,
  saveHistoryCallMessage,
  deleteHistoryCallMessage,
  saveBlankHistoryCallMessage,
  saveIsDisplayedHistoryCallModal,
  updateCall,
  saveCallsByCollaboratorUuid,
} from '../calls/actionCalls';
// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

// consts
import { HISTORY_STATUS_CLOSED, HISTORY_STATUS_OPENED } from "../calls/reducerCalls";
let initialCallsState = true;

const middlewareWebsocketFirebase = (store) => (next) => (action) => {
  const handleErrors = (tryName, error) => {
    store.dispatch(saveSnackbar(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${error.name} ~ ${error.code}`, 'error'));
  }
  switch (action.type) {
    // lancer par Landing au lancement de l'application
    // sert lorsque le client est modifié dans un call qui appartient a l'utilisateur 
    // et est présent la la liste des tickets en cours d'un autre utilisateur
    case CONNECT_WEBSOCKET_FIREBASE_CALLS:
      const listenCalls = () => {
          const db = getDb(store.getState().login.tenantUuid);
          const callsCol = collection(db, 'calls');


          console.log({ userUuid: action.userUuid, calls : store.getState().calls.calls });
        
          try {
            const q = query(callsCol,
              where("userId", "==", action.userUuid),
            );
            onSnapshot(q, (querySnapshot) => {
              // ne récupère pas la 1ere requete
              if (initialCallsState) {
                initialCallsState = false;
              } else {
                querySnapshot.docChanges().forEach((change) => {
                  const call = change.doc.data();
                  console.log(CONNECT_WEBSOCKET_FIREBASE_CALLS, change.type, {call})

                  if (change.type === "added" || change.type === "modified") {
                    if (!store.getState().calls.historyCalls.includes(call.historyId)) {
                      store.dispatch(connectWebsocketFirebaseHistoryCalls([{ id: call.historyId }]))
                    }
                  }
                })
              }
            });
          } catch (error) {
            console.log("CONNECT_WEBSOCKET_FIREBASE_CALLS ~ error:", error)
            handleErrors('CONNECT_WEBSOCKET_FIREBASE_CALLS', error);
          }
        }
    
      listenCalls()

      next(action)
      break

    // 30 historique max a la fois
    // Attention sauvegarde uniquement les ticket pour callsByCollaboratorUuid
    case CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS:
      const getCallsByCallHistory = async (historyCalls) => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          const historyCallsIds = historyCalls.map(({ id }) => id)
          const callsCol = collection(db, 'calls');

          const q = query(callsCol,
            // 30 historique max a la fois
            where("historyId", 'in', historyCallsIds),
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              // console.log("CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS ~ change.type:", change.type)
              const callByCollaboratorUuid = change.doc.data();
              const currentCallsByCollaboratorUuidIds = store.getState().calls.callsByCollaboratorUuid.map((({ id }) => id));
              // le call est modifié uniquement si il est déjà présent dans les state
              //
              if (change.type === "added" || (change.type === "modified" && currentCallsByCollaboratorUuidIds.includes(callByCollaboratorUuid.id))) {
                store.dispatch(saveCallsByCollaboratorUuid([callByCollaboratorUuid], store.getState().login.uuid));
              }
            })
          })

        } catch (error) {
          console.log("CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS, ~ error:", error)

          handleErrors('  CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS,', error);
        }
      }

      if (action.historyCalls.length > 0) {
        getCallsByCallHistory(action.historyCalls);
      }

      next(action)
      break

    // est appelé par calls.FETCH_CALLS au moment ou l'utisateur consulte les appels recents
    // est appelé par calls.POST_HISTORY_CALL au moment ou l'historique est sauvegardé en bd
    case CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS:
      const listenHistoryCalls = (historyCallIds) => {
        try {
          const db = getDb(store.getState().login.tenantUuid);
          const historyCallsCol = collection(db, 'historyCalls');
          const q = query(historyCallsCol,
            where("id", "in", historyCallIds),
          );
          onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              const historyCall = change.doc.data();
              // console.log(CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS, change.type, {historyCall})
              if (change.type === "added") {
                store.dispatch(saveHistoryCall(historyCall));
                // permet de s'abonner à au message de historyCall
                store.dispatch(connectWebsocketFirebaseHistoryCallMessages([historyCall.id]))
              }

              if (change.type === "modified") {
                store.dispatch(updateHistoryCall(historyCall));
              }

              if (change.type === "removed") {
                const callsByCollaboratorUuid = store.getState().calls.callsByCollaboratorUuid.filter((call) => historyCall.id === call.historyId)?.[0]
                if (callsByCollaboratorUuid) {
                  store.dispatch(deleteCallByCollaboratorUuid(callsByCollaboratorUuid))
                  store.dispatch(saveIsDisplayedHistoryCallModal(callsByCollaboratorUuid.historyCallId, false))
                }
              }
            })
          });
        } catch (error) {
          console.log("CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS ~ error:", error)
          handleErrors('CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS', error);
        }
      }

      // si l'historique est présent dans calls.historyCalls il a déjà été écouté
      const alreadyListenedHistoryCallsIds = store.getState().calls.historyCalls
        .map(({ id }) => id)
        ;

      // un historique sera écouté que si cela n'a pas déjà était fait
      const currentHistoryCalls = alreadyListenedHistoryCallsIds.length > 0
        ? (
          action.historyCalls
            .filter(
              ({ id }) => !alreadyListenedHistoryCallsIds.includes({ id })
            )
        )
        : (
          action.historyCalls
        )
      ;


      if (currentHistoryCalls.length > 0) {
        // divise currentHistoryCalls en tableau de 30 entré max sinon firebase plante
        const newArray = [];
        let tempArray = [];

        currentHistoryCalls.forEach((historyCall, index) => {
            // recupère uniquement l'id de l'historyCall
            tempArray.push(historyCall.id);
            if ((index + 1) % 30 === 0 || index === currentHistoryCalls.length - 1) {
                newArray.push(tempArray);
                tempArray = [];
            }
        });
        newArray.forEach((arr) => {
          listenHistoryCalls(arr);
        })
      }

      next(action)
      break

    // recupere les appels et les historiques non cloturé dont l'utilisateur connecté et le propriétaire présent en base de données.
    //  est appellé par Landing a chaque fois que l'appli se lance
    // puis dès que utlisateur selectionne cette utilisateur dans HistoryCallModal en tant que collabotareur
    case CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID:
      const listenCallCollaboratorUuid = () => {
        try {
          const db = getDb(store.getState().login.tenantUuid);
          const historyCallsCol = collection(db, 'historyCalls');

          const q = query(
            historyCallsCol,
            where("collaboratorUuid", "==", action.userUuid),
            where("status", "==", HISTORY_STATUS_OPENED),
          );
          // a la premiere connexion récupère tous les historique ouvert de l'utilisateur
          // ensuite uniquement au moment ou l'utilisateur devient collaborateur d'un ticket
          let initialSnapshot = true;
          onSnapshot(q, (querySnapshot) => {
            if (initialSnapshot) {
              const historyCalls = querySnapshot.docs.map(doc => doc.data());
              // recupere les calls liés au historique de l'utilisateur
              // puis met à jour les states
              store.dispatch(connectWebsocketFirebaseCallsByHistoryCalls(historyCalls));
              store.dispatch(saveHistoryCalls(historyCalls));
              // s'abonne aux message des historique
              store.dispatch(connectWebsocketFirebaseHistoryCallMessages(historyCalls.map(({ id }) => id)))
              initialSnapshot = false;
            }
            else {
              querySnapshot.docChanges().forEach((change) => {
                const historyCall = change.doc.data();
                // console.log(CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID, change.type, {historyCall})
                if (change.type === "added") {
                  // dans le cas ou l'utilisateur réouvre un ticket
                  // le call est déjà présent dans calls.callsByCollaboratorUuid
                  // il a été récupéré depuis le useEffect de CallsHistory grâce a fetchCallsByCollaboratorUuid
                  const closedCall = store.getState().calls.callsByCollaboratorUuid.filter(
                    (call) => historyCall?.id === call.historyId
                  )[0];

                  // si cette historique est déjà présent il sera mis à jour
                  if (closedCall) {
                    store.dispatch(updateHistoryCall(historyCall));
                  }
                  // sinon le callByCollaratorUuid devra etre récupéré grace a connectWebsocketFirebaseCallsByHistoryCalls
                  else {
                    store.dispatch(saveHistoryCall(historyCall));
                    store.dispatch(connectWebsocketFirebaseCallsByHistoryCalls([historyCall]));
                  }
                  store.dispatch(connectWebsocketFirebaseHistoryCallMessages([historyCall.id]))
                }

                if (change.type === "modified") {
                  store.dispatch(updateHistoryCall(historyCall));
                }

                if (change.type === "removed") {
                  const callsByCollaboratorUuid = store.getState().calls.callsByCollaboratorUuid.filter((call) => historyCall.id === call.historyId)[0]

                  const fetchHistoryCallById = async () => {
                    try {
                      const db =  getDb(store.getState().login.tenantUuid);
                      const historyCallsCol = collection(db, 'historyCalls');

                      const q = query(
                        historyCallsCol,
                        where("id", "==", historyCall.id),
                      )

                      const historyCallsSnapshot = await getDocs(q);
                      const historyCallsList = historyCallsSnapshot.docs.map(doc => doc.data());

                      const currentHistoryCall = historyCallsList?.[0];

                      // il faut verifier si c'est un ticket qui vient d'être cloturé ou une maj du collaborateur
                      if (currentHistoryCall && HISTORY_STATUS_CLOSED === currentHistoryCall.status) {
                        // si le ticket vient d'être cloturer il faut conserver le call pour l'affichage de l'historique
                        store.dispatch(updateHistoryCall(currentHistoryCall))
                      } else if (callsByCollaboratorUuid) {
                        store.dispatch(deleteCallByCollaboratorUuid(callsByCollaboratorUuid))
                      }
                    } catch (error) {
                      console.log("CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID ~ error:", error)
                      handleErrors('CCONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID', error);
                    }
                  }

                  fetchHistoryCallById();
                  store.dispatch(saveIsDisplayedHistoryCallModal(callsByCollaboratorUuid.id, false))
                }
              })
            }
          });
        } catch (error) {
          console.log("🚀 ~ file: middlewareWebsocketFirebase.js:199 ~ listenHistoryCalls ~ error:", error)
          handleErrors('listenHistoryCalls', error);
        }
      }

      listenCallCollaboratorUuid()

      next(action)
      break

    // est appelé par websocketFirebase.CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID et websocketFirebase.CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS
    // dès qu'un historyCall est "added"
    case CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES:
      const listenHistoryCallMessage = () => {
        try {
          const db =  getDb(store.getState().login.tenantUuid);
          const q = query(
            collection(db, 'historyCallMessages'),
            where("historyCallId", "in", action.historyCallIds),
            orderBy("date", "asc"),
          );


          onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
              // console.log(CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES, change.type)
              const historyCallMessage = { id: change.doc.id, ...change.doc.data() };

              if (change.type === "added") {
                store.dispatch(saveHistoryCallMessage(historyCallMessage))
              }

              if (change.type === "removed") {
                store.dispatch(deleteHistoryCallMessage(historyCallMessage))
              }
            });
          });
        } catch (error) {
          console.log("CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES ~ error:", error)
          handleErrors('CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES', error);
        }
      }

      if (action.historyCallIds.length > 0) {
        listenHistoryCallMessage();
        // permet de rajouter l'historyCall en tant que clé du state historyCallMessages
        store.dispatch(saveBlankHistoryCallMessage(action.historyCallIds[0]))
      }

      next(action)
      break

    default: next(action)
  }



}

export default middlewareWebsocketFirebase;
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from "react-router-dom";
// Components
import Loader from './Loader';
// containers
import CustomSnackbarContainer from '../containers/CustomSnackbarContainer';
import TabsPanelContainer from '../containers/TabsPanelContainer';
import TabsContainer from '../containers/TabsContainer';

// img
import logoAveliaBlanc from '../img/logo-avelia-blanc.png'

// MUI
import {
  Container,
  Box,
  Typography,
  Badge,
  Menu,
} from '@mui/material';
// Icons
import { useTheme } from '@mui/material/styles';

// Icons
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

// Styled components & icons
import {
  StyledImageBox,
  StyledMenuButton,
  StyledMenuIcon,
  StyledMenuItem,
} from "../styles/LandingStyle"

// consts
import {
  PAGE_CALLS,
  PAGE_CALL_HISTORY,
  PAGE_CLIENTS,
} from '../pages';

const Landing = ({
  openCallsByCollaboratorUuid,
  users,
  isAuthenticationLoading,
  isConnected,
  isConnectingWebsocket,
  calls,
  clients,
  isFetchingCalls,
  isFetchingUsers,
  isFetchingClients,
  isDisconnecting,
  userEmail,
  saveTabIndex,
  fetchUsers,
  fetchCalls,
  fetchClients,
  logOut,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [contextMenu, setContextMenu] = useState(null);
  const theme = useTheme();

  // présent également
  // TODO : si la deconnexion provient du middleware via CONNECT_TO_WAZO_APP.app.logout() l'affichage ne sera pas mis à jour et le loader ne s'affichera pas
  const isLoaderOn = isFetchingClients || isFetchingUsers || isConnectingWebsocket || isFetchingCalls || isDisconnecting || !isConnected;

  // Logout
  const handleLogout = useCallback((e) => {
    // le logout ne fonctionne que si la touche ctrl est utilisée
    if(e.ctrlKey && !isAuthenticationLoading){
      logOut();
      navigate('/login');
    }
  }, [navigate, logOut])

  // récupère les utilisateurs wazo
  useEffect(() => {
    if (isConnected && users.length === 0 && !isDisconnecting) {
      fetchUsers();
    }
  }, [fetchUsers, users.length, isConnected])

  useEffect(() => {
    if (isConnected && clients.length === 0 && !isDisconnecting) {
      fetchClients();
    }
  }, [fetchClients, clients.length, isConnected])

  useEffect(() => {
    // le but étant de charger les appels uniquement si cela n'a pas déjà été fait
    // uniquement les appels terminés seront chargés de cette façon c'est donc uniquement eux qui doivent etre vérifiés    
    const endedCallsList = calls.filter((call) => 'call_ended' === call.status);

    if (isConnected && PAGE_CALLS === location.pathname && endedCallsList.length === 0 && !isDisconnecting) {
      fetchCalls();
    }
  }, [fetchCalls, calls.length, isConnected, location.pathname])

  const getPageCallsTabIndex = useCallback(() => {
    const inProgressCalls = calls.filter(({ status }) => 'call_ended' !== status)

    let tabIndex = 0;
    let inProgressCall = {};

    if (0 !== calls.length) {
      inProgressCall = inProgressCalls[inProgressCalls.length - 1]
    }

    const status = inProgressCall?.status ?? 'call_ended';

    switch (status) {
      case 'call_created':
        // l'application ne bascule pas sur l'onglet "Entrant" si il y a déjà un appel en cours
        if (inProgressCalls.length < 2) {
          tabIndex = 2;
        }
        break;
      case 'call_answered':
        tabIndex = 0;
        break;
      case 'call_held':
        tabIndex = 1;
        break;
      case 'call_ended':
        tabIndex = 3;
        break;
      default:
        break;
    }

    return tabIndex;
  }, [calls])

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // Redirect --> /clients
  const handleOnClickClients = () => {
    saveTabIndex(PAGE_CLIENTS, 0);
    navigate(PAGE_CLIENTS);
    handleClose();
  }

  // Redirect --> /
  const handleOnClickCalls = () => {
    const tabIndex = getPageCallsTabIndex();
    saveTabIndex(PAGE_CALLS, tabIndex);
    navigate(PAGE_CALLS);
    handleClose();
  }

  // Redirect --> /callHistory
  const handleOnClickNotifications = () => {
    saveTabIndex(PAGE_CALL_HISTORY, 0);
    navigate(PAGE_CALL_HISTORY);
    handleClose();
  }

  return (
    <Container maxWidth="xxl" sx={{
      m: 0,
      px: '0!important',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}>
      <CustomSnackbarContainer />
      {/* Header */}
      <Box sx={{
        borderColor: 'divider',
        background: `linear-gradient(to top, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
        textAlign: 'center',
        color: 'secondary',
      }}>
        <Box sx={{ position: "relative", p: 3 }}>
          {/* Menus */}
          <Box sx={{
            position: 'absolute',
            top: ['5px', '12px'],
            left: ['5px', '12px'],
          }}>
            <Badge
              badgeContent={openCallsByCollaboratorUuid.length}
              color="error"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <StyledMenuButton
                onClick={handleContextMenu}
              >
                <StyledMenuIcon />
              </StyledMenuButton>
            </Badge>
          </Box>
          {/* logo avelia */}
          <StyledImageBox component="img" alt="Logo Avelia" src={logoAveliaBlanc} />
          {/* user email and hide logout */}
          <Typography 
            color="common.white"
            sx={{
              position: 'absolute',
              top: ['5px', '12px'],
              right: ['5px', '12px'],
              cursor: 'default',
            }}
            onClick={(e) => handleLogout(e)}
          >
            {userEmail}
          </Typography>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <StyledMenuItem onClick={handleOnClickCalls}>
              <Box display='flex' alignItems='center'>
                <LocalPhoneIcon sx={{ fontSize: 21 }} color='secondary' />
                <Typography variant="body1" sx={{ paddingInline: 2 }}>Appels</Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleOnClickClients}>
              <Box display='flex' alignItems='center'>
                <PersonIcon sx={{ fontSize: 21 }} color='secondary' />
                <Typography variant="body1" sx={{ paddingInline: 2 }}>Clients</Typography>
              </Box>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleOnClickNotifications}>
              <Box display='flex' alignItems='center'>
                <Badge
                  badgeContent={openCallsByCollaboratorUuid.length}
                  color="error"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap="circular"
                >
                  <EmailIcon color='secondary' />
                </Badge>
                <Typography variant="body1" sx={{ paddingInline: 2 }}>Mes tickets</Typography>
              </Box>
            </StyledMenuItem>
          </Menu>
        </Box>
        {/* onglet de navigation */}
        { !isLoaderOn && (
          <TabsContainer />
        )}
      </Box>
      {/* Main */}
      <Box sx={{
        backgroundColor: 'bg.main',
        overflow: 'hidden',
        paddingBottom: '2rem',
        height: '100%',
      }}>
        <>
          {/* contenu de l'onglet de navigation */}
          { !isLoaderOn
            ? (
              <TabsPanelContainer />
            )
            : 
            (
              <Loader size={200} color="secondary"/>
            ) 
          }
        </>
      </Box>
      {/* footer */}
      <Box sx={{
        backgroundColor: 'bg.main',
        position: 'absolute',
        bottom: 0,
        right: '21px',
      }}>
        <Typography sx={{
          textAlign: 'right',
          color: 'bg.dark',
          display: ['none', 'inherit'],
          '&:hover': {
            color: 'primary.light',
          },
        }}>
          { process.env.REACT_APP_VERSION }
        </Typography>
      </Box>
    </Container>
  )
}

Landing.propTypes = {
  // contient la liste des historique attribué a l'utilisateur connecté
  openCallsByCollaboratorUuid: PropTypes.array.isRequired,
  // liste des utilisateurs wazo
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  // si l'authentification est en cours
  isAuthenticationLoading: PropTypes.bool.isRequired,
  // si l'utilisateur est connecté
  isConnected: PropTypes.bool.isRequired,
  // indique si le processus de connection aux websocket est en cours
  isConnectingWebsocket: PropTypes.bool.isRequired,
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  // Liste des clients obtenue via l'api GENAPI
  clients: PropTypes.arrayOf(
    PropTypes.exact({
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      id: PropTypes.number.isRequired,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      tenantId: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  // si les Calls sont en cours de chargements
  isFetchingCalls: PropTypes.bool.isRequired,
  // si les Users sont en cours de chargements
  isFetchingUsers: PropTypes.bool.isRequired,
  // si les Clients sont en cours de chargements
  isFetchingClients: PropTypes.bool.isRequired,
  // si la deconnexion est en cours
  isDisconnecting: PropTypes.bool.isRequired,
  // email de l'utilisateur connecté
  userEmail: PropTypes.string.isRequired,
  // permet de changer d'onglet
  saveTabIndex: PropTypes.func.isRequired,
  // liste des utilisateurs Wazo
  fetchUsers: PropTypes.func.isRequired,
  // telecharge les appels depuis firestore
  fetchCalls: PropTypes.func.isRequired,
  // liste des clients present sur le CRM du client
  fetchClients: PropTypes.func.isRequired,
  // permet de lancer la deconnexion de l'application
  logOut: PropTypes.func.isRequired,
}

export default Landing

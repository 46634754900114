import React from 'react'
import PropTypes from 'prop-types'

// material
import { Typography } from '@mui/material'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

// Utils
import { getTwoDigitTime } from "../utils";

const Duration = ({ duration }) => {
    // Hours calculation
    let hours = Math.floor((duration % 86400 ) / 3600);
    hours = getTwoDigitTime(hours);

    // Minutes calculation
    let minutes = Math.floor((duration % 3600) / 60);
    minutes = getTwoDigitTime(minutes);

    // Seconds calculation
    let seconds = Math.floor((duration % 60));
    seconds = getTwoDigitTime(seconds);

  return (
    <>
      <AccessAlarmIcon color="secondary" />
      <Typography sx={{ color: "primary.light" }}>{hours}:{minutes}:{seconds}</Typography>
    </>

  )
}

Duration.propTypes = {
  // durée en seconde
  duration: PropTypes.number.isRequired,
}

export default Duration
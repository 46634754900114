export const SAVE_EMAIL = 'SAVE_EMAIL';
export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SAVE_SERVER = 'SAVE_SERVER';
export const SAVE_UUID = 'SAVE_UUID';
export const SAVE_TENANT_UUID = 'SAVE_TENANT_UUID';
export const SAVE_WAZO_TOKEN = 'SAVE_WAZO_TOKEN';
export const SAVE_REFRESH_TOKEN = 'SAVE_REFRESH_TOKEN';
export const SAVE_ERROR = 'SAVE_ERROR';
export const SAVE_EXPIRES_AT = 'SAVE_EXPIRES_AT';
export const SIGN_IN_WAZO = 'SIGN_IN_WAZO';
export const SIGN_IN_WAZO_WITH_CONTEXT = 'SIGN_IN_WAZO_WITH_CONTEXT';
export const SAVE_IS_AUTHENTICATION_LOADING = 'SAVE_IS_AUTHENTICATION_LOADING';
export const SAVE_IS_CONNECTED = 'SAVE_IS_CONNECTED';
export const SAVE_IS_CONNECTING_WITH_CONTEXT = 'SAVE_IS_CONNECTING_WITH_CONTEXT';
export const CHECK_IS_VALID_WAZO_TOKEN = 'CHECK_IS_VALID_WAZO_TOKEN';
export const CONNECT_TO_WAZO_APP = 'CONNECT_TO_WAZO_APP';
export const SAVE_IS_CHECKING_WAZO_TOKEN = 'SAVE_IS_CHECKING_WAZO_TOKEN';
export const CHECK_IS_MOST_RECENT_VERSION = 'CHECK_IS_MOST_RECENT_VERSION';
export const SAVE_IS_CHECKING_IS_MOST_RECENT_VERSION = 'SAVE_IS_CHECKING_IS_MOST_RECENT_VERSION';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const SAVE_IS_DISCONNECTING = 'SAVE_IS_DISCONNECTING';
export const SAVE_IS_CONNECTING_TO_WAZO_APP = 'SAVE_IS_CONNECTING_TO_WAZO_APP';
export const SAVE_IS_DICONNECT_WAZO_APP = 'SAVE_IS_DICONNECT_WAZO_APP';

export const saveEmail = (email) => ({
    type: SAVE_EMAIL,
    email,
})

export const savePassword = (password) => ({
    type: SAVE_PASSWORD,
    password,
})

export const saveServer = (server) => ({
    type: SAVE_SERVER,
    server,
})

export const saveUuid = (uuid) => ({
    type: SAVE_UUID,
    uuid,
})

export const saveTenantUuid = (tenantUuid) => ({
    type: SAVE_TENANT_UUID,
    tenantUuid,
})

export const saveWazoToken = (token) => ({
    type: SAVE_WAZO_TOKEN,
    token,
})

export const saveRefreshToken = (refreshToken) => ({
    type: SAVE_REFRESH_TOKEN,
    refreshToken,
})

export const saveExpiresAt = (expiresAt) => ({
    type: SAVE_EXPIRES_AT,
    expiresAt,
})

export const saveError = (error) => ({
    type: SAVE_ERROR,
    error,
})

export const signInWazo = (email, password, server) => ({
    type: SIGN_IN_WAZO,
    email,
    password,
    server,
})

export const signInWazoWithContext = (context) => ({
    type: SIGN_IN_WAZO_WITH_CONTEXT,
    context,
})

export const saveIsAuthenticationLoading = (isAuthenticationLoading) => ({
    type: SAVE_IS_AUTHENTICATION_LOADING,
    isAuthenticationLoading,
})

export const saveIsConnected = (isConnected) => ({
    type: SAVE_IS_CONNECTED,
    isConnected,
})

export const saveIsConnectingWithContext = (isConnectingWithContext) => ({
    type: SAVE_IS_CONNECTING_WITH_CONTEXT,
    isConnectingWithContext,
})

export const checkIsValidWazoToken = (wazoToken) => ({
    type: CHECK_IS_VALID_WAZO_TOKEN,
    wazoToken,
})

export const connectToWazoApp = () => ({
    type: CONNECT_TO_WAZO_APP,
})

export const saveIsCheckingWazoToken = (isCheckingWazoToken) => ({
    type: SAVE_IS_CHECKING_WAZO_TOKEN,
    isCheckingWazoToken,
})

export const checkIsMostRecentVersion = () => ({
    type: CHECK_IS_MOST_RECENT_VERSION,
})

export const saveIsCheckingIsMostRecentVersion = (isCheckingIsMostRecentVersion) => ({
    type: SAVE_IS_CHECKING_IS_MOST_RECENT_VERSION,
    isCheckingIsMostRecentVersion,
})

export const logIn = (token, refreshToken, uuid, tenantUuid, expireAt, email, server ) => ({
    type: LOG_IN,
    token,
    refreshToken,
    uuid,
    tenantUuid,
    expireAt,
    email,
    server,
})

export const logOut = () => ({
    type: LOG_OUT,
})

export const saveIsDisconnecting = (isDisconnecting) => ({
    type: SAVE_IS_DISCONNECTING,
    isDisconnecting,
})

export const saveIsConnectingToWazoApp = (isConnectingToWazoApp) => ({
    type: SAVE_IS_CONNECTING_TO_WAZO_APP,
    isConnectingToWazoApp,
})

export const saveIsDisconnectWazoApp = (isDisconnectWazoApp) => ({
    type: SAVE_IS_DICONNECT_WAZO_APP,
    isDisconnectWazoApp,
})
import { 
    SAVE_IS_DISPLAYED,
    SAVE_IS_DOWNLOADING, 
    SAVE_DOWNLOAD_PROGRESS,
    SAVE_IS_DOWNLOADED,
 } from "./actionUpdateApp";

const initialStateTest = {
    isDisplayed: false,
    isDownloading: false,
    downloadProgress: {
        total: 0,
        delta: 0,
        transferred: 0,
        percent: 0,
        bytesPerSecond: 0,
    },
    isDownloaded: false,
}

const reducerUpdateApp = (state = initialStateTest, action) => {

    switch (action.type){
        case SAVE_IS_DISPLAYED:
            return {
                ...state,
                isDisplayed: action.isDisplayed,
            }

        case SAVE_IS_DOWNLOADING:
            return {
                ...state,
                isDownloading: action.isDownloading,
            }

        case SAVE_DOWNLOAD_PROGRESS:
            return {
                ...state,
                downloadProgress: action.downloadProgress,
            }

        case SAVE_IS_DOWNLOADED:
            return {
                ...state,
                isDownloaded: action.isDownloaded,
            }

        default: return state
    }
}
export default reducerUpdateApp;
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams } from "react-router-dom";
// material
import {
  ThemeProvider,
  createTheme,
  Typography,
} from '@mui/material';
// Styled Component
import { StyledTab, StyledMateriaTabs, StyledBadge } from '../styles/TabsStyle';
import { pages } from '../pages';

const Tabs = ({
  tabsIndex,
  saveTabIndex,
  calls,
}) => {
  const location = useLocation()
  const routeParams = useParams();
  const clientId = routeParams.clientId;

  const getPage = useCallback(() => {
    return pages(clientId )?.[location.pathname];
  }, [clientId, location.pathname])

  const getTabIndex = useCallback(() => {
    return tabsIndex?.[location.pathname] ?? 0;
  }, [location.pathname, tabsIndex])

  const CustomIconTab = ({ children }) => {
    const theme = createTheme({
      components: {
        'MuiSvgIcon': {
          styleOverrides: {
            root: {
              margin: '0',
            }
          },
        }
      }
    });

    return (<ThemeProvider theme={theme}>{children}</ThemeProvider>)
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }
  // recupere le nombre d'appel par status present pour le badge des onglets de PAGE_CALLS
  const getCallLenghtByStatus = (status) => calls.filter(
    (call) => status === call.status,
  ).length;

  return (
    <StyledMateriaTabs value={getTabIndex() ?? 0} onChange={(e, newTabIndex) => saveTabIndex(location.pathname, newTabIndex)} aria-label="tabs" centered variant="fullWidth" TabIndicatorProps={{ style: { display: 'none' } }}>
      {
        getPage().tabs.map(
          (tab, index) => (
            <StyledTab 
              key={index}
              icon={<CustomIconTab>{tab.icon}</CustomIconTab>}
              {...a11yProps(index)}
              label={(
                <StyledBadge
                  badgeContent={tab?.badge ? getCallLenghtByStatus(tab?.badge) : null}
                  color="error"
                >
                  <Typography variant='h3'>{tab.name}</Typography>      
                </StyledBadge>
              )}
            />
          )
        )
      }
    </StyledMateriaTabs>
  )
}

Tabs.propTypes = {
  // indique sur quel onglet doivent se trouver les pages
  tabsIndex: PropTypes.object.isRequired,
  saveTabIndex: PropTypes.func.isRequired,
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
}

export default Tabs